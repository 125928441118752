import Vue from 'vue'
import App from './App.vue'
import Element from 'element-ui';
import router from './router';
import './assets/css/base.css'
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(Element)


new Vue({
  router, //引入路由文件
  render: h => h(App),
}).$mount('#app')
