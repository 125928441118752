import request from './request'
import api from "@/utils/api";
import {MessageBox} from "element-ui";
import router from "@/router";

export async function getToken(config){
  return new Promise((resolve, reject) => {
        //异步体
        config.method='get';
        let userinfo=api.userAccount;
        config.url=userinfo;
        request(config).then(res=>{
          //  console.log("获取拦截数据=="+JSON.stringify(res))
            if(res){
                let data=res.data;
                if(data.code==200){
                    resolve(true);
                }
                if(data.code==401){
                    //只能跳一次
                    let jumpOnce=localStorage.getItem("isJump");
                    if(!jumpOnce||jumpOnce=='false') {
                        MessageBox.alert('您需要重新登录', '令牌已过期', {
                            confirmButtonText: '知道了',
                            callback: action => {
                                localStorage.removeItem('token');
                                router.push("/login")
                            }
                        });
                       localStorage.setItem("isJump",'true');
                   }
                }
                // if(data.code==500){
                //    console.log("错误")
                // }

            }
        });
    }).then(res=>{
        return res;
  })

}
export function excludeUrl(config) {
  let flag=false;
  let urls=[
      api.userAccount,
      api.biddingListById,
      api.mybodingList,
      api.biddingList,
      api.supportPrise];
  for(let i=0;i<urls.length;i++){
      if(config.url.indexOf(urls[i])>=0){
          flag=true;
      }
  }
  return flag;
}

export async function goDo(config) {
    let result=await getToken(config);
      //  console.log("拿到结果:"+result)
    return result;
}
export function ProcessEvent(message,title,confirmText,cancelText) {
    let goOn=false;
    MessageBox.confirm(message, title, {
        confirmButtonText: confirmText,
        cancelButtonText: cancelText,
        type: 'warning'
    }).then(() => {
            goOn=true;
            router.push('/main/enterprise');
    }).catch(() => {
           goOn=false;
           console.log("已经取消了...")
    });
    return goOn;
}
