<template>
    <div>
        <el-dialog title="修改用户信息" :visible.sync="dialogFormVisible" :close-on-click-modal=false>
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="用户头像" :label-width="formLabelWidth" prop="nickName">
                    <el-upload
                            class="avatar-uploader"
                            action="#"
                            accept=".jpeg"
                            :http-request="imgUpload"
                            :show-file-list=false
                            :on-success="uploadSuccess"
                            :on-error="failStatus"
                    >
                        <img v-if="form.avatar" :src="form.avatar" class="avatar" style="height: 200px;height:200px;">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <div slot="tip" class="el-upload__tip" style="color:#000">
<!--                            提示：仅允许上传“jpg”、“jpeg”、“png”或“gif”格式文件！-->
                            提示：仅允许上传jpeg格式文件！
                        </div>
                    </el-upload>

                </el-form-item>
                <el-form-item label="呢称" :label-width="formLabelWidth" prop="nickName">
                    <el-input v-model="form.nickName" placeholder="请输入用户呢称" clearable></el-input>
                </el-form-item>

                <el-form-item label="真实姓名" :label-width="formLabelWidth" prop="realName">
                    <el-input v-model="form.realName"  placeholder="请输入真实姓名" clearable></el-input>
                </el-form-item>

                <el-form-item label="性别" :label-width="formLabelWidth">
                    <el-radio-group v-model="form.sex">
                        <el-radio-button label="0">男</el-radio-button>
                        <el-radio-button label="1">女</el-radio-button>
                    </el-radio-group>
                </el-form-item>

<!--                <el-form-item label="地址" :label-width="formLabelWidth">-->
<!--                    <el-input v-model="form.address"  placeholder="请输入地址" clearable></el-input>-->
<!--                </el-form-item>-->

                <el-form-item label="身份证编号" :label-width="formLabelWidth" prop="idcard">
                    <el-input v-model="form.idcard"  placeholder="请输入身份证" clearable></el-input>
                </el-form-item>

                <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
                <el-input v-model="form.email"  placeholder="请输入邮箱" clearable></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit('form')">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import http from "@/utils/http";
    import api from "@/utils/api";
    import {isEmail,checkIdCard} from "@/utils/validate";

    export default {
        name: "ModifyPersonalInfo",
        data(){
            return{
               // imageUrl:'https://file.yngy.cloud/statics/source/2022/04/13/19226269-9e8a-42fa-9d70-dd6dd9aa1843.jpg',
                uploadUrl:api.uploadFile,
                dialogFormVisible:false,
                form: {},
                formLabelWidth: '100px',
                rules:{
                    nickName:[{required: true, message: "请输入用户呢称", trigger: "blur"},],
                    email:[{validator: isEmail, message: "请输入正确的邮箱地址", trigger: 'blur' },],
                    idcard:[{validator: checkIdCard, message: "请输入正确的身份证号", trigger: 'blur,change' },]


                }
            }
        },
        inject:[
            'reload'
        ],
        methods:{
            async imgUpload(info){
                // console.log("原始对象"+info)
             if(info){
                 let file=info.file;
                 let fileName=info.file.name;
                 // console.log("file"+file)
                 // console.log("fileName"+fileName)
                 let formData = new FormData();
                 formData.append('authFile', file);
                 // 调用上传接口
                await http.upload(api.uploadFile,formData).then(res=>{
                     // console.log("请求服务"+JSON.stringify(res));
                     let data=res.data;
                     if(data.code==200){
                         let imageUrl=data.imgUrl;
                         // console.log("图片路径:"+imageUrl)
                         // console.log("表单里的数据:"+JSON.stringify(this.form))
                         this.form.avatar=imageUrl;



                     }

                 }).catch(err=>{
                     // console.log("错误拦截"+JSON.stringify(err))
                 })
             }

            },
            uploadSuccess(y) {
                // console.log(JSON.stringify(y))
            },
            failStatus(err) {
                // console.log("失败"+JSON.stringify(err))

            },
            openModifyPersonDiaog(from){
                if(from){
                  //  // console.log("这里的值:"+JSON.stringify(from))
                    this.dialogFormVisible = true;
                    this.form=from
                }

            },
            submit(from){
                this.$refs[from].validate(valid=>{
                    if(valid){
                        // console.log(valid)
                        // console.log("success submit!!");

                        let params={
                            userId:this.form.userId,
                            nickName:this.form.nickName,
                            userName:this.form.userName,
                            realName:this.form.realName,
                            sex:this.form.sex,
                            address:this.form.address,
                            idcard:this.form.idcard,
                            email:this.form.email,
                            avatar:this.form.avatar
                        }
                        // console.log("准备提交的参数 ："+JSON.stringify(params))
                        http.put(api.updateProfile,params).then(res=>{
                            // console.log("提交后返回的参数 ："+JSON.stringify(res))
                            let data=res.data;
                            if(data.code==200){
                                this.$message.success("个人信息更新成功......");
                                this.dialogFormVisible = false;
                                this.reload();
                              //  this.$router.push("/main/info");
                            }
                            else{
                                this.$message.error(data.msg)
                            }
                        }).catch(err=>{
                            // console.log(err)
                        })
                    }
                    else{
                        // console.log("error submit!!");
                    }

                });
            },
        }
    }
</script>

<style scoped>
    .el-upload-dragger{
        width: auto;
        height: auto;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
