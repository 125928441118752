<template>
    <div class="header-width">
        <div>
            <img src="../../../assets/img/gylogo.png" style="width: 35px;height: 35px;" alt="">
            <span class="showTitle">云南钢友集采系统</span>
<!--            <span>钢友招采系统</span>-->
            <el-tooltip class="item" effect="dark" content="开关" placement="top">
            <i style="font-size: 30px;margin: 0px 20px;position:relative;top:-5px;" :class="isopen==true?'el-icon-s-unfold':'el-icon-s-fold'" @click="changecloseable"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="刷新" placement="top">
            <i style="font-size: 30px;margin: 0px 20px;position:relative;top:-5px;"  class="el-icon-refresh"  @click="refresh"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="网站首页" placement="top">
            <i style="font-size: 35px;margin: 0px 20px;position:relative;top:-5px;"  class="el-icon-s-home"  @click="toHome"></i>
            </el-tooltip>
        </div>
<!--        <avatar-menu :user-info="userInfo" :imagesrc="imagesrc"></avatar-menu>-->
        <avatar-menu :user-info="userInfo"></avatar-menu>

    </div>
</template>

<script>
    import AvatarMenu from "@/components/main/subHeader/avatar/AvatarMenu";
    export default {
        name: "SubHeader",
        props:['userInfo','imagesrc'],
        inject:[
            'reload'
        ],
        data(){
          return{
              isopen:false,
          }
        },
        components:{
            AvatarMenu
        },
        methods:{
            changecloseable(){
              this.isopen=!this.isopen;
              this.$emit("isopen",this.isopen);
            },
            refresh(){
                this.reload();
            },
            toHome(){
              this.$router.push("/")
            },

        }
    }
</script>

<style scoped>
  .header-width{
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 0px 20px;
  }

  .showTitle{
      font-size: 17px;
      position: relative;
      top: -10px;
      left: 10px;
      font-weight: bold;

  }
</style>
