<template>
    <div class="customContainer">
        <el-card class="box-card customContainer">
            <div slot="header" class="clearfix">
                <bread-header jup-path="/main/user" jup-page="用户管理" />
<!--                <span>用户管理</span>-->
<!--                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
            </div>
            <!--表格菜单-->
            <div class="menuList">
                <div class="menuItem">
                    <div class="space" style="min-width: fit-content">用户名:</div>
                    <el-input  label="用户名2"  v-model="userName" :clearable="true" size="medium" placeholder="请输入内容" prefix-icon="el-icon-date"/>
                    <el-button class="space" type="primary" @click="findUser">查询</el-button>
                    <el-button class="space" type="success" @click="addUser">添加</el-button>
                </div>
            </div>

            <div class="baseTable">
            <el-table
                    :data="tableData"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="id"
                        label="编号"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="userName"
                        label="姓名"
                        width="180">
                </el-table-column>
                <el-table-column width="180"
                                 prop="phone"
                                 label="电话">
                </el-table-column>
                <el-table-column width="80"
                                 prop="passWord"
                                 label="密码">
                </el-table-column>
                <el-table-column width="80"
                                 prop="role"
                                 label="角色名">
                </el-table-column>

                <el-table-column
                        label="操作">
                    <template slot-scope="scope">
                        <el-button @click="editUser(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button type="text" size="small" @click="deleteUser(scope.row)">删除</el-button>
                    </template>
                </el-table-column>


            </el-table>
            <el-pagination class="footerPage"
                           background
                           @current-change="handleCurrentChange"
                           layout="prev, pager, next"
                           :total="total">
            </el-pagination>
            </div>
        </el-card>
        <add-or-modify-user  ref="dialog1" v-on:isUpdate="handleUpdate"/>
    </div>
</template>

<script>
    import BreadHeader from "@/components/breadHeader/BreadHeader";
    import common from "@/commFunction/common";
    import http from "@/utils/http";
    import api from "@/utils/api";
    import addOrModifyUser from "@/components/user/addOrModifyUser";
    export default {
        name: "User",
        components: {
            BreadHeader,
            addOrModifyUser
        },
        mounted() {
            this._initData();
        },
        data(){
            return{
                userName:'',
                tableData:[],
                dialog1Visible:false,
                pageSize:10,
                pageNum:1,
                total:0
            }
        },
        methods:{
            _initData(){
             let ld=common.showDialog(".baseTable")
                let params={
                    pageSize:this.pageSize,
                    pageNum:this.pageNum
                }
             http.post(api.userlist,params).then(res=>{
                 let data=res.data;
                 if(data.code==200){
                     let innerData=data.data;
                     // console.log("这里的数据"+JSON.stringify(innerData))
                     let list=innerData.list;
                     this.tableData=list;
                     let total=innerData.total;
                     this.total=total;
                 }
                 else{
                     this.$message.error(data.msg)
                 }
             }).catch(err=>{
                 // console.log(err)
             })
                common.closeDialog(ld);
            },
            findUser() {
                // console.log("查找用户信息")
                let userId = this.userName;
                // console.log("用户的id" + userId)
                if(userId){
                let params = {
                    id: userId
                }
                http.post(api.userinfo, params).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        // console.log("数据123：" + JSON.stringify(data))
                        let innerData = data.data;
                        // console.log("内部数据123：" + JSON.stringify(innerData))
                        let tableArray = new Array();
                        if (innerData) {
                            tableArray.push(innerData);
                            this.tableData = tableArray;
                        }
                        else{
                            this.tableData=tableArray;
                        }
                    } else {
                        this.$message.error(data.msg)
                    }
                }).catch(err => {
                    // console.log(err)
                })
              }
              else{
                  this._initData();
                }
            },
            addUser(){
                this.$nextTick(()=>{
                    this.$refs.dialog1.openDialog1Visible();
                });

            },
            editUser(rowIndex){
                // console.log("传入的"+JSON.stringify(rowIndex))
                this.$nextTick(()=>{
                    this.$refs.dialog1.openDialog1Visible(rowIndex);
                });

            },
            //接收子组件传回的值
            handleUpdate(target) {  //自定义方法触发事件
               // console.log("我是父组件:"+target)
                if(target){
                    this._initData();
                }
             },
            deleteUser(from){
                // console.log("要修改的对象值:"+JSON.stringify(from))
                let id=from.id;
                this.$confirm('是否删除该记录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let params={
                        id:id
                    }
                    http.post(api.deleteUser,params).then(res=> {
                        let data=res.data;
                        if(data.code==200){
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this._initData();
                        }
                        else{
                            this.$message.error(data.msg)
                        }
                    }).catch(err=>{
                        this.$message({
                            type: 'warning',
                            message: err
                        });
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //分页
            handleCurrentChange(currentPage){
                // console.log("你点击了第几页"+currentPage)
                //设置页面参数
                this.pageNum=currentPage;
                this._initData();
            }

        }
    }
</script>

<style scoped>
   .menuList{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    font-size: 14px;
    }
    .menuList .menuItem span{
        width: 10%;
    }
    .menuList .menuItem{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .menuList .menuItem .space{
        margin: 0px 5px;
    }
   .baseTable{
       margin-top: 50px;
   }
   .footerPage{
       float: right;
       margin-bottom: 50px;
       margin-top: 10px;

   }
</style>
