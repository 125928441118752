<template>
    <div>
        <el-menu :default-active="defaultIndex" class="headposition" mode="horizontal" @select="changeMenu">
            <el-menu-item index="0" style="border-bottom: none;"><el-image :src="logoImage"/></el-menu-item>
            <el-menu-item index="1" class="first" @click="tabs(1)">首页</el-menu-item>
<!--            <el-menu-item index="2" @click="tabs(2,'项目公示')">项目公示</el-menu-item>--改版-->
            <el-menu-item index="2" @click="tabs(2,'采购进行中')">采购进行中</el-menu-item>
<!--            <el-menu-item index="3" @click="tabs(3,'项目进行中')">项目进行中</el-menu-item>-->
            <el-menu-item index="4" @click="tabs(4,'采购已过期')">采购已过期</el-menu-item>
            <el-menu-item index="5" @click="tabs(5,'结果公示')">结果公示</el-menu-item>

            <el-menu-item  v-if="isloginShow" index="5" style="border-bottom: none; padding: 0px 5px;margin-left: 50px;"><el-button v-if="isShowHeader==true" type="primary" @click="login">登录</el-button></el-menu-item>
            <el-menu-item v-if="isloginShow" index="6" style="border-bottom: none; padding: 0px 5px;"><el-button v-if="isShowHeader==true" type="primary" @click="register">免费注册</el-button></el-menu-item>
            <el-menu-item v-if="isloginShow" index="7" style="border-bottom: none; padding: 0px 5px;"><el-button v-if="isShowHeader==false" style="margin-left: 50px;" type="primary" @click="backSystem">返回后台系统</el-button></el-menu-item>
        </el-menu>
        <div class="basehr"></div>
        <div v-if="isShowContent" class="showContent">
            <div class="table_box" style="width: 80%;margin-top: 20px;">
                <custom-blance></custom-blance>
                <el-divider/>
                <el-breadcrumb v-if="isshow==true"  separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item ref="hd" class="customIndexPi" style="font-weight: bold;" :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item v-if="id!=1">{{tabvalue}}</el-breadcrumb-item>
                </el-breadcrumb>
                <el-breadcrumb v-if="isshow==false"   separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item><span style="font-weight: bold;">{{customTitle}}</span></el-breadcrumb-item>
                </el-breadcrumb>
                <div v-if="isshow==false">
                 <div class="btnshow" @click="isDetail" v-if="status==1"><el-button type="primary">去报价</el-button></div>
<!--                 <div class="btnshow" @click="isDetail" v-if="status==2"><el-button type="warning">招标中</el-button></div>-->
                 <div class="btnshow" v-if="status==2"><el-button type="info" disabled>已截止</el-button></div>
                </div>
                <el-divider/>
            </div>
        </div>
    </div>
</template>

<script>

    import CustomBlance from "@/components/blance/blance";
    import {offsetLeft} from '@/utils/aryee';
    export default {
        name: "HomeHeader",
        components: {CustomBlance},
        props:{
            isshow:{
                type:Boolean,
                default:true
            },
            isShowContent:{
                type:Boolean,
                default:true
            },
            customTitle:{
               type: String,
               default: ''
            },
            status:{
                type:Number,
                default:0
            },
        },
        data(){
            return {
                logoImage: require('@/assets/img/yngylogo.png'),
                id:1,
                tabvalue:'',
                isShowHeader:true, //是否显示登录组件
                isloginShow:true,
                left:10,
                defaultIndex:'1'


            }
        },
        mounted() {
         this.checkToken();
            let routeName=this.$route.name;
            if(routeName){
                if(routeName=='Login'){
                    this.isloginShow=false;
                    this.defaultIndex='';
                }
                if(routeName=='Register'){
                    this.isloginShow=false;
                    this.defaultIndex='';
                }
            }


        },
        methods:{
            recevieId(index,tabvalue){
               // console.log("子组件获取index"+index)
                this.defaultIndex=index+'';
                this.id=index;
                this.tabvalue=tabvalue;
            },
            changeMenu(index,indexPath){
              //  console.log("选中的index"+index+"他的路径:"+indexPath)
                this.defaultIndex=index;

            },
            checkToken(){
                let token=localStorage.getItem('token');
                if(token) {
                    this.isShowHeader = false
                }
                else{
                    this.isShowHeader=true;
                }
            },
            //首页检查是否登录
            isDetail(){
                let id=this.$route.params.id;
                // console.log("得到的id"+id)
                let token=localStorage.getItem('token');
                if(!token){
                    this.login();
                }
                else{
                    //跳转到竞价页面
                    // console.log("这里的token"+token)
                    this.$router.push("/main/biddingDetail/"+id);
                }

            },
            tabs(id,value){
                 console.log("传入的"+id)
                this.id=id;
                this.tabvalue=value;
                let pageName=this.$route.name;
                if(pageName=='Home') {
                     this.$emit('getType',{
                         id:id,
                         tabvalue:value
                     })
                }
                else {
                    this.$router.push({name: 'Home', params: {id: id,tabvalue:value}})
                }

            },
            login(){
                // console.log("即将跳转.......")
                this.$router.push('/login')
            },
            register(){
                this.$router.push('/register')
            },
            backSystem(){
                this.$router.push('/main')
            },
        }
    }
</script>

<style scoped>
    .el-menu.el-menu--horizontal{
        border-bottom: none;
    }
    .crow{
        font-size: 14px;
        color: #606266;
    }
    .headposition{
        display: flex;
        flex-direction: row;
        /* justify-content: center; */
        margin-left: 7.9%;
    }
    /*.first{*/
    /*    margin-top: 20%;*/
    /*}*/

    .el-menu-item{
        padding:0 40px;
        letter-spacing: 5px;
    }
    .content{
        display: flex;
        flex-direction: row;

    }
    .showContent{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
    }

    /* 去除表格的边框 */
    .table_box /deep/ td.el-table__cell {
        border: none;
    }
    .table_box /deep/ th.el-table__cell {
        border: none;
    }
    .user_box /deep/ .el-table::before {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;
    }
    .transition-box {
        margin-bottom: 10px;
        width: 200px;
        height: 100px;
        border-radius: 4px;
        background-color: #409EFF;
        text-align: center;
        color: #fff;
        padding: 40px 20px;
        box-sizing: border-box;
        margin-right: 20px;
    }
    el-table__header tr.el-table__header th {
        padding: 0;
        height: 30px;
        line-height: 30px;
    }
    .menuList{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        font-size: 14px;
    }
    .menuList .menuItem span{
        width: 10%;
    }
    .menuList .menuItem{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .menuList .menuItem .space{
        margin: 0px 5px;
    }
    .baseTable{

    }
    .footerPage{
        float: right;
        margin-bottom: 50px;
        margin-top: 10px;

    }
    .fixWeight{
        margin-left: 10px;
    }
    .btnshow{
        float: right;
        font-size: 15px;
        margin-right: 60px;
        position: relative;
        top: -30px;
    }
</style>
