/****   http.js   ****/
// 导入封装好的axios实例
import request from './request'
import {getToken, excludeUrl,goDo} from '@/utils/auth'
import {tansParams} from "@/utils/aryee";

const http = {
    /**
     * methods: 请求
     * @param url 请求地址
     * @param params 请求参数
     */
    async  get(url, params) {
        const config = {
            method: 'get'
        }
        // config.url=url;
        // if(params) config.params = params;
       let tempParams=params;
       config.url = url + '?' + tansParams(params);
       config.params = {};
        if (excludeUrl(config)) {
            getToken(config);
        }
       config.url = url + '?' + tansParams(tempParams);
       return request(config)
    },

    async post(url, params) {
        console.log(url, params)
        const config = {
            method: 'post',
            url: url
        }
        let tempUrl=url;
        if (params) config.data = params;
        if (excludeUrl(config)) {
            let res= await goDo(config);
            if(res==true){
                config.method = 'post';
                config.url=tempUrl;
             //   console.log("打印参数 ："+JSON.stringify(config))
                return request(config)
            }
        }
        else {
            config.method = 'post';
            return request(config);
        }
    },
    async put(url, params) {
        const config = {
            method: 'put',
            url: url
        }
        let tempUrl=url;
        if (params)
            config.data = params;
        if (excludeUrl(config)) {
         let res= await goDo(config);
            if(res==true){
                config.method = 'put';
                config.url=tempUrl;
                //console.log("打印参数 ："+JSON.stringify(config))
                return request(config)
            }
        }
        else {
            config.method = 'put';
            return request(config)
        }
    },
    async upload(url, params) {
        const config = {
            method: 'upload',
            url: url
        }
        if (params) config.data = params
        if (excludeUrl(config)) {
            getToken(config);
        }
        config.method = 'upload';
        return request(config)
    },

    async putQuery(url, params) {
        const config = {
            method: 'put',
            url: url
        }
        if (params) config.params = params;
        if (excludeUrl(config)) {
            getToken(config);
        }
        config.method = 'put';
        return request(config)
    },
    async delete(url, params) {
        const config = {
            method: 'delete',
            url: url
        }
        if (params) config.data = params;
        if (excludeUrl(config)) {
            getToken(config);
        }
        config.method = 'delete';
        return request(config)
    }
}
//导出
export default http
