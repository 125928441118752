<template>
    <div class="customContainer">
        <el-card class="box-card customContainer">
            <div slot="header" class="clearfix">
                <bread-header jup-path="/main/info" jup-page="用户信息" />
            </div>
         <div class="inner">
             <el-descriptions :model="userInfo" class="margin-top" title="账号信息" :column="1"  border>
                 <el-descriptions-item>
                     <template slot="label">
                         <i class="el-icon-user"></i>
                         头像
                     </template>
                     <el-image :preview-src-list="userInfo.avatar?[userInfo.avatar]:[defaultImageSrc]" :src="userInfo.avatar?userInfo.avatar:defaultImageSrc" class="personImage"/>
                 </el-descriptions-item>
                 <el-descriptions-item label-style="width:200px;">
                     <template slot="label">
                         <i class="el-icon-user"></i>
                         用户名
                     </template>
                     {{userInfo.userName}}
                 </el-descriptions-item>
                 <el-descriptions-item>
                     <template slot="label">
                         <i class="el-icon-user"></i>
                         呢称
                     </template>
                     {{userInfo.nickName}}
                 </el-descriptions-item>
                 <el-descriptions-item>
                     <template slot="label">
                         <i class="el-icon-user"></i>
                         真实姓名:
                     </template>
                     {{userInfo.realName}}
                 </el-descriptions-item>
                 <el-descriptions-item>
                     <template slot="label">
                         <i class="el-icon-mobile-phone"></i>
                         手机号
                     </template>
                     {{userInfo.phone}}
                 </el-descriptions-item>
                 <el-descriptions-item>
                     <template slot="label">
                         <i class="el-icon-location-outline"></i>
                         性别
                     </template>
                    {{userInfo.sex==0?'男':'女'}}
                 </el-descriptions-item>
                 <el-descriptions-item>
                     <template slot="label">
                         <i class="el-icon-tickets"></i>
                         身份证编号
                     </template>
                    {{userInfo.idcard}}
                 </el-descriptions-item>
                 <el-descriptions-item>
                     <template slot="label">
                         <i class="el-icon-tickets"></i>
                         邮箱
                     </template>
                    {{userInfo.email}}
                 </el-descriptions-item>
                 <el-descriptions-item>
                     <template slot="label">
                         <i class="el-icon-tickets"></i>
                         登录ip
                     </template>
                    {{userInfo.loginIp}}
                 </el-descriptions-item>
<!--                 <el-descriptions-item>-->
<!--                     <template slot="label">-->
<!--                         <i class="el-icon-tickets"></i>-->
<!--                         当前账号状态-->
<!--                     </template>-->
<!--                     {{userInfo.status}}-->
<!--                 </el-descriptions-item>-->
<!--                 <el-descriptions-item>-->
<!--                     <template slot="label">-->
<!--                         <i class="el-icon-location-outline"></i>-->
<!--                         地址-->
<!--                     </template>-->
<!--                     {{userInfo.address}}-->
<!--                 </el-descriptions-item>-->
             </el-descriptions>

         </div>
            <el-button class="modityBtn" type="primary" @click="updateUserInfo(userInfo)">修改资料</el-button>
        </el-card>
        <ModifyPersonalInfo  ref="ModifyPersondialog" />
    </div>
</template>

<script>
    import BreadHeader from "@/components/breadHeader/BreadHeader";
    import http from "@/utils/http";
    import api from "@/utils/api";
    import ModifyPersonalInfo from "@/components/personal/ModifyPersonalInfo";
    import common from "@/commFunction/common";
    export default {
        name: "PersonalInfo",
        components: {
            BreadHeader,
            ModifyPersonalInfo

        },
        mounted() {
            this.getAccountInfo();
        },
        data(){
            return{
                userInfo:{},
                defaultImageSrc:require('@/assets/img/default.jpeg')
            }
        },
        methods:{
            getAccountInfo(){
                let ld=common.showDialog(".customContainer")
                http.get(api.userAccount,{}).then(res=>{
                    // // console.log("结果:"+JSON.stringify(res))
                    let data=res.data;
                    if(data.code==200){
                        let permission=data.permissions;
                        let roles=data.roles;
                        let users=data.users;
                        if(users){
                            this.userInfo=users;
                            // // console.log("用户id"+this.userInfo.userId)
                        }

                    }
                }).catch(erro=>{
                    this.$message.error(erro)
                });
                common.closeDialog(ld);
            },
            updateUserInfo(form){
             this.$nextTick(()=>{
                 this.$refs.ModifyPersondialog.openModifyPersonDiaog(form);
             });
            }
        }

    }
</script>

<style scoped>
.personImage{
    width: 100px;
    height: 100px;
}.inner{
    display: flex;
    flex-direction: column;
 }
.modityBtn{
    margin-top: 20px;
    margin-left: 45%;

}
</style>
