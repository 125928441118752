<template>
    <div class="customContainer">
        <el-card class="box-card customContainer">
            <div slot="header" class="clearfix">
                <bread-header jup-path="/main/mybidding" jup-page="我的报价" />
            </div>
            <div class="menuList">
                <div class="menuItem">
<!--                    <el-date-picker class="fixWeight" size="medium" style="width: 300px;"-->
<!--                                    v-model="dateValue"-->
<!--                                    type="date"-->
<!--                                    placeholder="选择日期">-->
<!--                    </el-date-picker>-->
<!--                    <el-select class="fixWeight"  size="medium" v-model="selectValue" placeholder="请选择" clearable>-->
<!--                        <el-option-->
<!--                                v-for="item in options"-->
<!--                                :key="item.value"-->
<!--                                :label="item.label"-->
<!--                                :value="item.value"-->
<!--                        >-->
<!--                        </el-option>-->
<!--                    </el-select>-->
                    <el-input v-model="findNum" style="width: 250px;" size="medium" placeholder="计划编号" prefix-icon="el-icon-date" clearable />
                    <el-input v-model="findTitle" style="width: 250px;margin-left: 10px;" size="medium" placeholder="计划标题" prefix-icon="el-icon-date" clearable />
                    <el-button class="space" type="primary" @click="findRecord">查询</el-button>
                </div>
            </div>
            <el-divider/>
            <div class="baseTable">
                <el-table
                        :data="tableData"
                        border
                        style="width: 100%;" :header-cell-style="tableHeaderCellStyle"  :default-sort = "{prop: 'createtime', order: 'descending'}"
                        :row-key="getRowKeys"
                        :expand-row-keys="expands"
                        @row-click="clickRowHandle"
                >
                    <!--内嵌表格-->
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-table
                                    :data="props.row.prodetailList"
                                    border
                                    style="width: 100%;" :header-cell-style="SubtableHeaderCellStyle">
                            <el-table-column prop="product_name" label="品名"/>
                            <el-table-column prop="textofmate" label="材质"/>
                            <el-table-column prop="specification" label="规格"/>
                            <el-table-column prop="quantity" label="数量"/>
                                <el-table-column prop="product_unit" label="单位">
                                    <template slot-scope="scope">
                                        {{scope.row.product_unit==1?"吨":"卷"}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="weightmetohd" label="计重方式">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.weight_method==1">理记</div>
                                        <div v-if="scope.row.weight_method==2">磅记</div>
                                    </template>
                                </el-table-column>
                            <el-table-column prop="demandorigin" label="交货地点"/>
                            <el-table-column prop="expedeliverydate" label="期望交货期"/>
                            <el-table-column prop="remark" label="备注"/>
                                <el-table-column prop="feedbackPrice" label="竞标价格">
                                   <template slot-scope="scope">
                                      {{parseInt(scope.row.price).toFixed(2)}}
                                   </template>
                                </el-table-column>
                                <el-table-column
                                        prop="isStatus"
                                        label="中标状态">
                                    <template slot-scope="scope">
                                        <el-tag type="success" v-if="scope.row.isStatus === 0">在审核</el-tag>
                                        <el-tag type="success" v-else-if="scope.row.isStatus === 1">已中标</el-tag>
                                        <el-tag type="danger"  v-else>未中标</el-tag>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                        prop="reason"
                                        label="未中标原因">
                                </el-table-column>

                            </el-table>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="pro_plan_num"
                            label="计划编号"
                            width="150"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="pro_plan_title"
                            label="计划标题"
                            width="300"
                    >
                    </el-table-column>
<!--                    <el-table-column-->
<!--                            prop="customer_type"-->
<!--                            label="客户类型">-->
<!--                        <template slot-scope="scope">-->
<!--                            <div v-if="scope.row.customer_type==1">贸易商</div>-->
<!--                            <div v-if="scope.row.customer_type==2">终端</div>-->
<!--                            <div v-if="scope.row.customer_type==3">次终端</div>-->
<!--                        </template>-->
<!--                    </el-table-column>-->

                    <el-table-column
                            prop="createtime"
                            label="投标时间">
                    </el-table-column>

                    <el-table-column
                            prop="remark"
                            label="备注">
                    </el-table-column>

                    <el-table-column
                          label="操作">
                     <template slot-scope="scope">
                       <el-button @click="downContract(scope.row)" type="text" size="small">下载合同</el-button>
                      </template>
                     </el-table-column>

                </el-table>
                <el-pagination class="footerPage"
                               background
                               @current-change="handleCurrentChange"
                               layout="prev, pager, next"
                               :total="total">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>

    import common from "@/commFunction/common";

    const  monidata=[{
        //计划编号
        "pro_plan_num": "20220325001",
        //计划标题
        "pro_plan_title": "钢材一批",
        //客户名称
        "customer_name": "云南建投物流",
        //客户类型
        "customer_type": "2",
        //付款方式
        "pay_type": "1",
        //货款金额
        "pay_amount": 6000.0,
        //预付比例(%)
        "pay_percent": "20",
        //收款日期
        "closing_date": "2022-03-30",
        //交货时间
        "delivery_time": "2022-03-29",
        //报价时间
        "quotedprice_time": "2022-03-28",
        //用户ID
        "account_id": "1485498200381214722",
        //是否删除标记
        "del_flag": 0,
        //备注
        "remark": "发货速度快一点",
        //创建时间
        "createtime": "2022-03-25",
        //任务状态
        "status": 1,

        "task_status": 0,
        "task_id": 1,
        "id": 1,
         innerData:[
             {
                 //品名
                 "product_name": "热轧普碳卷",
                 //材质
                 "textofmate": "Q355C",
                 //规格
                 "specification": "8.00*1500*C",
                 //数量
                 "quantity": 60,
                 //单位
                 "product_unit": "1",
                 //计重方式
                 "weightmetohd": "2",
                 //需求产地
                 "demandorigin": "攀钢，柳钢",
                 //期望交货期
                 "expedeliverydate": "2022-03-31",
                 //备注
                 "remark": "厚度正公差交货",
                 //创建时间
                 "createtime": "2022-03-25",
                 //采购计划ID
                 "pro_plan_id": 1,
                 //明细状态
                 "status": 1,

                 "id": 1,
                 "feedbackPrice": 0.00,
             }
         ]

    }];


    import BreadHeader from "@/components/breadHeader/BreadHeader";
    import {parseTime} from "@/utils/aryee";
    import http from "@/utils/http";
    import api from "@/utils/api";
    export default {
        name: "MyBidding",
        components: {
            BreadHeader
        },
        mounted() {
            let t= parseTime('2022-03-28T08:00:00','{y}-{m}-{d}');
            // console.log("时间"+t);
            this._initData();
        },
        data() {
            return {
                // 获取row的key值
                getRowKeys(row) {
                    return row.id;
                },
                // 要展开的行，数值的元素是row的key值
                expands: [],

                options: [{
                    value: '1',
                    label: '已中标'
                }, {
                    value: '2',
                    label: '未中标',
                }],
                findValue: '' ,
                selectValue:'',
                dateValue:'',
                pageSize:10,
                pageNum:1,
                total:0,
                tableData:[],
                findTitle:'',
                findNum:''
            }
        },
        methods:{
            clickRowHandle(row, column, event) {
                if (this.expands.includes(row.id)) {
                    this.expands = this.expands.filter(val => val !== row.id);
                } else {
                    this.expands.push(row.id);
                }
            },
            //分页
            handleCurrentChange(currentPage){
                // console.log("你点击了第几页"+currentPage)
                //设置页面参数
                this.pageNum=currentPage;
                this._initData();
            },
            tableHeaderCellStyle({row,column,rowIndex, columnIndex}) {
                let cellStyle1;
                cellStyle1= "background-color:#EAEDF1"
                return cellStyle1;
            },
            SubtableHeaderCellStyle({row,column,rowIndex, columnIndex}) {
                let cellStyle1;
                cellStyle1= "background-color:#fafafa"
                return cellStyle1;
            },
            findRecord(){
                //获取关键字参数
                let findTitle=this.findTitle;
                let findNum=this.findNum;
                let params=new Object();
                if(findTitle!=''&&findTitle!=null&&findTitle!=undefined){
                    params.pro_plan_title=findTitle;
                }
                if(findNum!=''&&findNum!=null&&findNum!=undefined){
                    params.pro_plan_num=findNum;
                }
                this._initData(params);
            },
            _initData(params){
                let ld=common.showDialog(".baseTable")
                if(!params){
                    params=new Object();
                }
                if(params){
                params.status=1;
                params.pageNum=this.pageNum;
                params.pageSize=this.pageSize;
                }

                // console.log("这里的参数=============="+JSON.stringify(params))
                let url=api.mybodingList
                http.get(url,params).then(res=>{
                   //  console.log("获取我的竞价==============:"+JSON.stringify(res));
                    if(res) {
                    let data=res.data;
                        if (data.code == 200) {
                            // console.log("222==============:" + JSON.stringify(data));
                            let newDatas = new Array();
                            let dataArray = data.rows;
                            // console.log("shujuuu=="+data.total)
                            for (let i = 0; i < dataArray.length; i++) {
                                let obj = dataArray[i];
                                let tempCreateTime = parseTime(obj.createtime, '{y}-{m}-{d}');//创建时间转化
                                let tempclosing_date = parseTime(obj.closing_date, '{y}-{m}-{d}');//创建时间转化
                                let tempquotedprice_time = parseTime(obj.quotedprice_time, '{y}-{m}-{d}');//创建时间转化
                                let tempdelivery_time = parseTime(obj.delivery_time, '{y}-{m}-{d}');//创建时间转化
                                dataArray[i].createtime = tempCreateTime;
                                dataArray[i].closing_date = tempclosing_date;
                                dataArray[i].quotedprice_time = tempquotedprice_time;
                                dataArray[i].delivery_time = tempdelivery_time;
                                let rows = dataArray[i].prodetailList;
                                for (let j = 0; j < rows.length; j++) {
                                    let objj = rows[j];
                                    let tempexpedeliverydate = parseTime(objj.expedeliverydate, '{y}-{m}-{d}');//创建时间转化
                                    rows[j].expedeliverydate = tempexpedeliverydate;
                                }
                                newDatas.push(dataArray[i]);
                            }
                            this.tableData = dataArray;
                            this.total = data.total;
                            // console.log("总数"+data.total)
                        }
                    }

                }).catch(err=>{
                    // console.log(err)
                })

             //   this.tableData=monidata;
               // this.total=monidata.length;
                common.closeDialog(ld);

            },
            downContract(row){
                let proPlanId=row.bid;
                let enterpriseId=localStorage.getItem("enterpriseId")
                let params={
                    pro_plan_id:proPlanId,
                    enterprise_id:enterpriseId
                }
                if(proPlanId&&enterpriseId){
                    http.get(api.downContract,params).then(res=>{
                       // console.log("合同返回数据："+JSON.stringify(res))
                        if(res) {
                            let data = res.data;
                            if (data.code == 200){
                                //获取合同地址下载
                                 let src=data.contractPath;
                               //  let src="https://file.yngy.cloud/statics/source/2022/04/18/3ef3b394-6abc-4ea7-bfed-314e0cfaf991.jpeg"
                                 if(src){
                                     window.open(src,'_blank')
                                 }
                            }
                            if(data.code==500){
                                this.$message.error(data.msg)
                            }
                        }
                    }).catch(err=>{

                    });
                }


            }
        },
    }
</script>

<style scoped>
    .menuList{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        font-size: 14px;
    }
    .menuList .menuItem span{
        width: 10%;
    }
    .menuList .menuItem{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .menuList .menuItem .space{
        margin: 0px 5px;
    }
    .baseTable{
        margin-top: 5px;
    }
    .footerPage{
        float: right;
        margin-bottom: 50px;
        margin-top: 10px;

    }
    .fixWeight{
        margin-left: 10px;
    }

</style>
