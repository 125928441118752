<template>
  <div class="showContent">
    <div class="con-bottom">
      <div class="con">
        <div class="copy clearfix">
          <p v-if="siteInfo.name" class="en">Copyright @ <span class="zh">{{ siteInfo.name }}</span> All Rights Reserved.</p>
          <p v-if="siteInfo.icp"><a :href="'http://beian.miit.gov.cn'" target="_blank">{{ siteInfo.icp }}</a></p>
          <p v-if="siteInfo.serviceEmail">{{ siteInfo.serviceEmail }}</p>
          <p v-if="siteInfo.serviceHot">{{ siteInfo.serviceHot }}</p>
          <p v-if="siteInfo.salesHot">{{ siteInfo.salesHot }}</p>
          <p v-if="siteInfo.complaint">{{ siteInfo.complaint }}</p>
          <p v-if="siteInfo.address">{{ siteInfo.address }}</p>
        </div>
        <div class="qr">
          <div class="img">
            <img v-if="siteInfo.mpQr" :src="siteInfo.mpQr" alt="云南钢友订阅号">
            <p>云南钢友订阅号</p>
          </div>
          <div class="img">
            <img v-if="siteInfo.mpServiceQr" :src="siteInfo.mpServiceQr" alt="云南钢友服务号">
            <p>云南钢友服务号</p>
          </div>
          <div class="img">
            <img v-if="siteInfo.wxVideoQr" :src="siteInfo.wxVideoQr" alt="云南钢友视频号">
            <p>云南钢友视频号</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/utils/http";

export default {
  name: "Bottom",
  data() {
    return {
      siteInfo: {
        icp: null,
        icp1: '滇公网安备：53019102000383号',
        name: '云南钢友工贸有限公司',
        email: 'email@aryee.cn',
        address: '云南省昆明市呈贡区',
        tel: null,
        serviceEmail: 'kf@aryee.cn',
        serviceHot: null,
        salesHot:'销售热线：0871-67416600 0871-63910580 0871-67415522 0871-67415085',
        complaint: '',
        // complaint: '销售服务投诉：0871-67415559，物流服务投诉：0871-67415087',
        mpQr: require('@/assets/img/mp.jpg'),
        mpServiceQr: require('@/assets/img/mpService.jpg'),
        wxVideoQr: require('@/assets/img/wxVideo.jpg')
      },
    }
  },
  created() {
    http.get("/api/account/config/configKey/site.config", {}).then(
        res => {
          if (res.data && res.data.code === 200) {
            let info = JSON.parse(res.data.msg);
            if (info.icp) this.siteInfo.icp = info.icp;
            if (info.icp1) this.siteInfo.icp1 = info.icp1;
            if (info.name) this.siteInfo.name = info.name;
            if (info.email) this.siteInfo.email = info.email;
            if (info.address) this.siteInfo.address = info.address;
            if (info.tel) this.siteInfo.tel = info.tel;
            if (info.serviceEmail) this.siteInfo.serviceEmail = info.serviceEmail;
            if (info.serviceHot) this.siteInfo.serviceHot = info.serviceHot;
            if (info.complaint) this.siteInfo.complaint = info.complaint;
            if (info.mpQr) this.siteInfo.mpQr = info.mpQr;
            if (info.mpServiceQr) this.siteInfo.mpServiceQr = info.mpServiceQr;
            if (info.wxVideoQr) this.siteInfo.wxVideoQr = info.wxVideoQr;
          }
        }
    );
  }
}
</script>

<style scoped>

.con-bottom {
  margin-top: 30px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
}

.con-bottom .con {
  padding: 0 10%;
}

.con-bottom .con .qr {
  float: left;
  padding: 0.8rem 1.2rem;
}

.con-bottom .con .qr .img {
  float: left;
  padding: 0.8rem 0.3rem;
}
.con-bottom .con .qr .img p {
  font-size: 0.9rem;
  text-align: center;
}
.con-bottom .con .qr .img img {
  height: 130px;
}
.con-bottom .con .copy {
  color: #666;
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  /*font-size: 14px;*/
  padding: 1.2rem 0;
  float: left;
}

.con-bottom .con .copy .en {
  font-size: 16px;
}

.con-bottom .con .copy .zh {
  font-size: 14px;
}

.con-bottom .con .copy a {
  color: #666;
  text-decoration: none;
}

.con-bottom .con .copy a:hover {
  color: #ee0000;
}

.showContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.showContent .con-bottom .con p {
  display: block;
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}
</style>
