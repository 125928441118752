import Vue from 'vue'

import Router from 'vue-router'

Vue.use(Router)
//解决重复路由的红色
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//引入各个部分的组件
import HelloWorld from '@/components/HelloWorld'
import Login from "@/components/login/Login";
import Register from "@/components/register/Register";
import Home from "@/components/home/Home";
import Main from "@/components/main/Main";
import User from "@/components/user/User";
import DefaultMain from "@/components/main/defaultMain/DefaultMain";
import PersonalInfo from "@/components/personal/PersonalInfo";
import Enterprise from "@/components/enterprise/Enterprise";
import AddEnterprise from "@/components/enterprise/AddEnterprise";
import Bidding from "@/components/bidding/Bidding";
import MyBidding from "@/components/mybidding/MyBidding";
import BiddingDetail from "@/components/bidding/BiddingDetail";
import HomeBoddingDetail from "@/components/home/HomeBoddingDetail";
import NoSupport from "@/components/NoSuport/NoSupport";
// import Contract from "@/components/contract/Contract";
import Test from "@/components/Test";
export default new Router({
    routes: [
        { path :'/HelloWorld', name : 'HelloWorld', component : HelloWorld },
        { path :'/', name : 'Home', component : Home },
        { path :'/homedetail/:id', name : 'HomeBoddingDetail', component : HomeBoddingDetail },
        { path :'/login', name : 'Login', component : Login },
        { path :'/test', name : 'Test', component : Test },
        { path :'/register', name : 'Register', component : Register },
        { path :'/NoSupport', name : 'NoSupport', component : NoSupport },
        {
            path :'/main', name : 'Main', component : Main,
            children:[
                {path:'',name:'DefaultMain',component:DefaultMain},
                {path:'user',name:'User',component:User},
                {path:'info',name:'PersonalInfo',component:PersonalInfo,meta: {name:"personInfo"}},
                {path:'enterprise',name:'Enterprise',component:Enterprise,meta:{name:"enterpriseInfo"}},
                {path:'addEnterprise',name:'AddEnterprise',component:AddEnterprise},
                {path:'addEnterprise/:id',name:'AddEnterprise',component:AddEnterprise},
                {path:'bidding',name:'Bidding',component:Bidding,meta:{name:"biddinglist"}},
                {path:'mybidding',name:'MyBidding',component:MyBidding, meta:{name:"mybiddinglist"}},
                {path:'biddingDetail/:id',name:'BiddingDetail',component:BiddingDetail},
                // {path:'contract',name:'Contract',component:Contract,meta:{name:"contractlist"}},
            ]
        },




],
      mode: "history"
})
