<template>
  <div class="">
    <home-header ref="hh" @getType="getType" :isshow="isshow"></home-header>
    <div class="showContent">
      <div class="content-box">
        <el-card v-if="tableFeildShow2">
          <div class="menuList">
            <div class="menuItem">
              <el-input ref="findNum" v-model="findNum" size="medium" style="width: 200px;" placeholder="项目编号"
                        prefix-icon="el-icon-date" clearable/>
              <el-input v-model="findValue" class="fixWeight" style="width: 200px;" size="medium" placeholder="项目名称"
                        prefix-icon="el-icon-date" clearable/>
              <el-date-picker class="fixWeight" size="medium" style="width: 200px;"
                              v-model="dateValue"
                              type="date"
                              placeholder="选择日期">
              </el-date-picker>
              <!--                                            <el-select class="fixWeight"  size="medium" v-model="selectValue" placeholder="项目状态" clearable>-->
              <!--                                                <el-option-->
              <!--                                                        v-for="item in options"-->
              <!--                                                        :key="item.value"-->
              <!--                                                        :label="item.label"-->
              <!--                                                        :value="item.value"-->
              <!--                                                >-->
              <!--                                                </el-option>-->
              <!--                                            </el-select>-->
              <el-button class="space" type="primary" @click="find">查询</el-button>
              <el-button class="space" type="primary" @click="reset">重置</el-button>
            </div>
          </div>
        </el-card>
        <el-divider v-if="tableFeildShow2"/>
        <el-card>
          <div style="font-size: 14px;font-weight: bold;">
            <span v-if="type==1">采购项目</span>
<!--            <span v-if="type==2">项目公示</span>-->
            <span v-if="type==2">采购进行中</span>
            <span v-if="type==3">采购进行中</span>
            <span v-if="type==4">过期项目</span>
            <span v-if="type==5">结果公式</span>
          </div>
          <div class="baseTable">
            <el-table
                    :data="tableData"
                    style="width: 100%"
                    :stripe=true
                    @row-click="openDetails"
            >
              <!--                                    <el-table-column prop="name" label="招标名称"/>-->
              <el-table-column prop="status" min-width="20%" label="项目状态"  >
                <template slot-scope="scope">
                  <el-row style="color: red;">
                    <el-col v-if="scope.row.status==2">
                      <el-tag effect="dark" type="info">已截止</el-tag>
                    </el-col>
                    <el-col v-else-if="scope.row.status==5">
                      <el-tag effect="dark" type="danger">已完成</el-tag>
                    </el-col>
                    <el-col v-else>
                      <el-tag effect="dark">招标中</el-tag>
                    </el-col>
                    <!--                                                <el-col v-if="scope.row.status==3">-->
                    <!--                                                    <el-tag effect="dark" type="danger">进行中</el-tag>-->
                    <!--                                                </el-col>-->
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column prop="pro_plan_num" min-width="30%" label="项目编号" :show-overflow-tooltip="true" sortable/>
              <el-table-column prop="pro_plan_title" min-width="30%" label="项目名称" :show-overflow-tooltip="true" >
                <template slot-scope="scope">
                  <el-link  v-if="scope.row.status!=5" type="info" rel="opener" :href="'/homedetail/'+scope.row.id" target="_blank"
                           style="color:#409EFF;">{{ scope.row.pro_plan_title }}
                  </el-link>
                  <div  v-if="scope.row.status==5"
                            style="color:#E6A23C;font-weight: bold;">{{ scope.row.pro_plan_title }}
                  </div>
                </template>
              </el-table-column>
              <!--项目公示开始-->
              <el-table-column v-if="tableFeildShow1"  prop="product_name" min-width="30%" label="品名" :show-overflow-tooltip="true" >
              </el-table-column>
              <el-table-column v-if="tableFeildShow1" prop="textofmate" min-width="20%" label="材质" :show-overflow-tooltip="true" sortable>
              </el-table-column>
              <el-table-column v-if="tableFeildShow1" prop="specification" min-width="30%" label="规格" :show-overflow-tooltip="true" sortable>
              </el-table-column>
              <el-table-column v-if="tableFeildShow1" prop="exeStand" min-width="30%" label="执行标准" :show-overflow-tooltip="true" sortable>
              </el-table-column>
              <el-table-column v-if="tableFeildShow1" prop="account_name" min-width="30%" label="供应商" :show-overflow-tooltip="true" sortable>
                <template slot-scope="scope">
                  <div style="color:#409EFF;font-weight: bold;">{{ scope.row.account_name}}</div>
                </template>
              </el-table-column>

              <el-table-column v-if="tableFeildShow1" prop="price" min-width="30%" :key="Math.random()" label="供应商报价" :show-overflow-tooltip="true" sortable>
                <template slot-scope="scope">
                  <div style="color:red;font-weight: bold;">{{ scope.row.dealprice}}</div>
                </template>
              </el-table-column>
              <el-table-column v-if="tableFeildShow1" prop="price" min-width="30%" :key="Math.random()" label="成交价格" :show-overflow-tooltip="true" sortable>
                <template slot-scope="scope">
                  <div style="color:red;font-weight: bold;">{{ scope.row.price}}</div>
                </template>
              </el-table-column>

              <el-table-column v-if="tableFeildShow1" prop="goods_address" min-width="30%" :key="Math.random()" label="装货地址" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <div style="color:#E6A23C;font-weight: bold;">{{scope.row.goods_address}}</div>
                </template>
              </el-table-column>


              <el-table-column  v-if="tableFeildShow2" prop="starttime" min-width="20%" label="发布时间" :show-overflow-tooltip="true" sortable/>
              <el-table-column  v-if="tableFeildShow2" prop="endtime" min-width="20%" label="截止时间" :show-overflow-tooltip="true" sortable/>
              <el-table-column prop="pro_plan_title" min-width="10%" >
                <template slot-scope="scope">
                  <el-link v-if="scope.row.status!=5" type="info" rel="opener" :href="'/homedetail/'+scope.row.id" target="_blank"
                           style="color:#409EFF;">项目详情
                  </el-link>
                </template>
              </el-table-column>

            </el-table>
            <!--                                    <el-pagination-->
            <!--                                            @size-change="handleSizeChange"-->
            <!--                                            @current-change="handleCurrentChange"-->
            <!--                                            :current-page="currentPage4"-->
            <!--                                            :page-sizes="[100, 200, 300, 400]"-->
            <!--                                            :page-size="100"-->
            <!--                                            layout="total, sizes, prev, pager, next, jumper"-->
            <!--                                            :total="total">-->
            <!--                                    </el-pagination>-->
            <el-pagination class="footerPage"
                           background
                           @current-change="handleCurrentChange"
                           layout="prev, pager, next"
                           :current-page="this.query.pageNum"
                           :total="total">
            </el-pagination>
          </div>
        </el-card>
      </div>
    </div>
    <custom-bottom></custom-bottom>
  </div>
</template>

<script>
  import CustomBlance from "@/components/blance/blance";
  import CustomBottom from "@/components/footer/Bottom";
  import common from "@/commFunction/common";
  import HomeHeader from "@/components/home/HomeHeader";
  import http from "@/utils/http";
  import api from "@/utils/api";
  import {addDateRange, parseTime} from '@/utils/aryee';

  export default {
    name: "Home",
    components: {CustomBottom, CustomBlance, HomeHeader},
    data() {
      return {
        type: 0,//表单类型 0:所有标集合 1:招标中(未有人投标),2竞标中(已有人投标),3投标结果
        logoImage: require('@/assets/img/yngylogo.png'),
        id: 1,
        tabvalue: '',
        show: true,
        total: 0,
        dateValue: '',
        findValue: '',
        findNum: '',
        selectValue: [],
        isshow:true,
        tableFeildShow1:false,
        tableFeildShow2:false,
        options: [
          {
            value: '1',
            label: '招标中',
          },
          {
            value: '3',
            label: '进行中',
          },
          {
            value: '2',
            label: '已截止',
          }
        ],
        query: {pageNum: 1, pageSize: 10},
        tableData: [],
      }
    },
    mounted() {
      let tabvalue=this.$route.params.tabvalue
      let id=this.$route.params.id
      let obj={
        id:id,
        tabvalue:tabvalue
      }
     // console.log("这里的id"+id)
      this.getType(obj);
    },
    methods: {
      getType(type1) {
        let type=type1.id;
        let tabvalue=type1.tabvalue;
        //console.log("id"+type+"tab"+tabvalue)
        if(type) {
          this.$refs.hh.recevieId(type,tabvalue);
        }
        this.type = type;
        if (type) {
          //等于2显示招标
          if (this.type === 1) {
            this.query={pageNum: 1, pageSize: 10}
          }
          if (this.type === 2) {
            this.query={pageNum: 1, pageSize: 10,status:1}
          }
          if (this.type === 3) {
            this.query.status = 3;
          }
          if (this.type === 4) {
            this.query={pageNum: 1, pageSize: 10,status:2}
          }
          if (this.type === 5) {
            this.query={pageNum: 1, pageSize: 10,status:5}
          }
        }
        this._initData();
      },
      _initData() {
        //根据type来动态加载
        let ld = common.showDialog(".baseTable");
        //加载api
  //      console.log("这里的参数:"+JSON.stringify(this.query))
          if(this.query.status==5){
             let params={
               pageNum: this.query.pageNum,
               pageSize: this.query.pageSize
             };
             http.get(api.indexProgramShow, params).then(res => {
              // console.log("获取的数据 ："+JSON.stringify(res))
              if (res) {
                let data = res.data;
                if (data.code == 200) {
                  let dataArray = data.rows;
                  for (let i = 0; i < dataArray.length; i++) {
                    //let obj = dataArray[i];
                    // let date=new Date(obj.createtime)
                    // let tempCreateTime = parseTime(date, '{y}-{m}-{d} {h}:{i}:{s}');//创建时间转化
                    // let endDate=new Date(obj.endtime);
                    // let tempendtime = parseTime(endDate, '{y}-{m}-{d} {h}:{i}:{s}');//创建时间转化
                    // dataArray[i].starttime = tempCreateTime;
                    // dataArray[i].endtime = tempendtime;
                    //添加已完成状态5
                    dataArray[i].status=5;
                  }
                  this.tableData = dataArray;
                  this.total = data.total;
                  this.tableFeildShow1=true;
                  this.tableFeildShow2=false;
                  //console.log("这里的数据:"+JSON.stringify(dataArray))
                }
              }
            }).catch(err => {
              this.$message.error(err)
            });
          }
          else{
           // console.log("pass")
            http.get(api.homeIndex, this.query).then(res => {
              if (res) {
                let data = res.data;
                if (data.code == 200) {
                  let dataArray = data.rows;
                  for (let i = 0; i < dataArray.length; i++) {
                    let obj = dataArray[i];
                    let date=new Date(obj.createtime)
                    let tempCreateTime = parseTime(date, '{y}-{m}-{d} {h}:{i}:{s}');//创建时间转化
                    let endDate=new Date(obj.endtime);
                    let tempendtime = parseTime(endDate, '{y}-{m}-{d} {h}:{i}:{s}');//创建时间转化
                    dataArray[i].starttime = tempCreateTime;
                    dataArray[i].endtime = tempendtime;
                  }
                  this.tableData = dataArray;
                  this.total = data.total;
                  this.tableFeildShow2=true;
                  this.tableFeildShow1=false;
                }
              }
            }).catch(err => {
              this.$message.error(err)
            });
          }

        setTimeout(() => {
          common.closeDialog(ld);
        }, 500)
      },
      currentPage4() {
        //做
      },
      handleSizeChange() {

      },
      openDetails(rows){
      //  console.log("这里"+JSON.stringify(rows))
      },
      handleCurrentChange(currentPage) {
        //设置页面参数
        this.query.pageNum = currentPage;
        this._initData();
      },
      find() {
        //获取参数
        let findValue = this.findValue;
        let findNum = this.findNum;
        let dateValue = this.dateValue;
        if (findValue) {
          this.query.pro_plan_title = findValue;
        }
        else{
          this.query.pro_plan_title='';
        }
        if (findNum) {
          this.query.pro_plan_num = findNum;
        }
        else{
          this.query.pro_plan_num='';
        }
        if (dateValue) {
          let now = parseTime(dateValue, '{y}-{m}-{d}');
          // this.query.params = {};
          // this.query.params["beginTime"] = now;
          // this.query.params["endTime"] = now;
          this.query = addDateRange(this.query,[now,now])
        }
        else{
          this.query.params={};
        }
        this._initData();
      },
      reset(){
        this.dateValue='';
        this.findValue='';
        this.findNum='';
        this.query.params={};
        this.query.pro_plan_title={};
        this.query.pro_plan_num={};
        this.query.pageNum=1;

        this._initData();
      },
      tabs(id, value) {
        this.id = id;
        this.tabvalue = value;

      },
      login() {
        this.$router.push('/login')
      },
      register() {
        this.$router.push('/register')
      },
    }
  }
</script>

<style scoped>
  .crow {
    font-size: 14px;
    color: #606266;
  }

  /*.headposition {*/
  /*  display: flex;*/
  /*  flex-direction: row;*/
  /*  !* justify-content: center; *!*/
  /*  margin-left: 100px;*/
  /*}*/

  .first {
    margin-top: 20%;
  }

  .el-menu-item {
    padding: 0 40px;
    letter-spacing: 5px;
  }

  .content {
    display: flex;
    flex-direction: row;

  }

  /* 去除表格的边框 */
  .table_box /deep/ td.el-table__cell {
    border: none;
  }

  .table_box /deep/ th.el-table__cell {
    border: none;
  }

  .user_box /deep/ .el-table::before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
  }

  el-table__header tr.el-table__header th {
    padding: 0;
    height: 30px;
    line-height: 30px;
  }

  .menuList {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 14px;
  }

  .menuList .menuItem span {
    width: 10%;
  }

  .menuList .menuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .menuList .menuItem .space {
    margin: 0px 5px;
  }

  .baseTable {

  }

  .footerPage {
    float: right;
    margin-bottom: 50px;
    margin-top: 10px;

  }

  .fixWeight {
    margin-left: 10px;
  }

</style>
