<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-click-modal=false :close-on-press-escape=false>
            <el-table
                    :data=tableData
                    stripe
                    :cell-style="cellStyle"
                    style="width: 100%">
                <el-table-column prop="product_name" :label=productName />
                <el-table-column prop="textofmate" label="材质"/>
                <el-table-column prop="specification" label="规格"/>
                <el-table-column prop="amount" :label=amountLabel />
                <el-table-column prop="product_unit" label="单位">
                    <template slot-scope="scope">
                        {{scope.row.product_unit==1?"吨":"卷"}}
                    </template>
                </el-table-column>
                <el-table-column prop="price" :label=moneyLable />
                <el-table-column prop="weight_method" label="计重方式" >
                    <template slot-scope="scope">
                        <div v-if="scope.row.weight_method==1">理记</div>
                        <div v-if="scope.row.weight_method==2">磅记</div>
                    </template>
                </el-table-column>
                <el-table-column prop="is_tax" label="是否含税" >
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_tax==1">不含税</div>
                        <div v-if="scope.row.is_tax==2">含税</div>
                    </template>
                </el-table-column>

                <el-table-column prop="remark"  :label=remarkLabel />
                <el-table-column prop="goods_address"  :label=goodsAddressLabel />
                <el-table-column prop="manufacturer"  label="生产厂家"/>

                <el-table-column prop="qualityPash"  label="质保书">
                    <template slot-scope="scope">
                     <div  v-if="scope.row.qualityPash&&scope.row.qualityPash.length>0">
                     <el-image  v-for="item in scope.row.qualityPash" :src="item"  :preview-src-list="scope.row.qualityPash"></el-image>
                     </div>
                    </template>
                </el-table-column>

            </el-table>
<!--            &lt;!&ndash;显示图片对话框-&ndash;&gt;-->
<!--            <el-dialog :title="ImageTitle" ref="showImage" :visible.sync="showImageDialogVisible">-->
<!--                <el-image :src="ImageSrc" />-->
<!--            </el-dialog>-->

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit(tableData)">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import http from "@/utils/http";
    import api from "@/utils/api";
    export default {
        name: "BiddingDetailView",
        data(){
         return{
             title:'报价预览',
             tableData:[],
             dialogFormVisible:false,
             moneyLable:'此次报价',
             amountLabel:'预报数量',
             productName:'品名',
             remarkLabel:'报价备注',
             goodsAddressLabel: '装货地点',
             //质保书图片
             ImageTitle:'质保书',
             from:'', //存一下临时的表单对象
             ImageSrc:'',
             showImageDialogVisible:false,
             showImageDefault:[]
         }
        },
        methods:{
            //单元格样式
            cellStyle({row,column}){
                if(column.label==this.moneyLable){
                 return "color:red";
                }
                if(column.label==this.amountLabel){
                 return "color:red";
                }
                if(column.label==this.productName){
                 return "color:#67c23a";
                }
                if(column.label==this.remarkLabel){
                 return "color:red";
                }
                if(column.label==this.goodsAddressLabel){
                    return "color:red";
                }
            },
            openBiddingBeSureDialogVisible(from){
                if(from){
                   // console.log("这里传过来的值:"+JSON.stringify(from))
                    this.tableData=from;
                    this.dialogFormVisible = true;

                }
            },
            submit(from){
                //console.log("表单里的对象:"+JSON.stringify(from));

                let pushArray=from;
                //移除属性
                for(let i=0;i<pushArray.length;i++){
                    delete pushArray[i].product_name;
                }
                //将质保书转成一个string
                for(let i=0;i<pushArray.length;i++){
                    let qualityPashArray= pushArray[i].qualityPash;
                    let qualityPashtemp='';
                    if(qualityPashArray&&qualityPashArray.length>0){
                        for(let j=0;j<qualityPashArray.length;j++){
                            let url=qualityPashArray[j];
                              qualityPashtemp = qualityPashtemp + url+',';
                        }
                         pushArray[i].qualityPash=qualityPashtemp.substr(0,qualityPashtemp.lastIndexOf(',')).trim();
                    }
                    else{
                        delete pushArray[i].qualityPash;
                    }

                }

                //准备提交数据
                //console.log("准备数据123:"+JSON.stringify(pushArray));
                http.put(api.supportPrise,pushArray).then(res=>{
                  //   console.log("服务器返回:"+JSON.stringify(res))
                    let data=res.data;
                    if(data.code==200){
                        this.$message.success('竞标成功');
                        this.$router.push("/main/bidding")
                    }
                    if(data.code==500){
                        this.$message.error(data.msg);
                    }
                 //   console.log("结果:"+JSON.stringify(data))
                }).catch(err=>{
                     console.log("错误"+err)
                })
                //在把字符串转成list
                for(let i=0;i<pushArray.length;i++){
                    let qualityPashImage= pushArray[i].qualityPash;
                    let qualityPashImageList=new Array();
                    if(qualityPashImage&&qualityPashImage.indexOf(",")>0){
                         qualityPashImageList= qualityPashImage.split(",");
                    }
                    else{
                        qualityPashImageList.push(qualityPashImage);
                    }
                    pushArray[i].qualityPash=qualityPashImageList;
                }
               this.dialogFormVisible=false;
            },
        }

    }
</script>

<style scoped>

</style>
