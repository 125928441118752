<template>
    <div class="customContainer">
        <el-card class="box-card customContainer">
            <div slot="header" class="clearfix">
                <bread-header jup-path="/main/enterprise" jup-page="企业信息" />
            </div>
            <div class="inner">
                <div v-if="isEnterpriseShow">
                    <!--显示资料-->
                    <div v-if="enterpriseInfo.approveStatus=='success'">
                        <span class="successres">审核已通过</span>
                    </div>
                    <el-descriptions title="企业资料" :column="2" size="medium" border>
<!--                        <el-descriptions-item label="企业营业执照">-->
<!--                            <el-image-->
<!--                                    :preview-src-list="enterpriseInfo.businessLicensePicture?[enterpriseInfo.businessLicensePicture]:[]"-->
<!--                                    style="width: 100px; height: 100px"-->
<!--                                    :src="enterpriseInfo.businessLicensePicture"-->
<!--                                    fit="fit"></el-image>-->
<!--                        </el-descriptions-item>-->
                        <el-descriptions-item labelClassName="customFixWidthLabel" label="企业名称">{{enterpriseInfo.enterpriseName}}</el-descriptions-item>
                        <el-descriptions-item label="企业编号">{{enterpriseInfo.enterpriseId}}</el-descriptions-item>
                        <el-descriptions-item label="企业统一社会代码">{{enterpriseInfo.orgCode}}</el-descriptions-item>
                        <el-descriptions-item label="法人">{{enterpriseInfo.legalPersonName}}</el-descriptions-item>
                        <el-descriptions-item v-if="enterpriseInfo.legalPersonPhone!=null" label="法人电话">{{enterpriseInfo.legalPersonPhone}}</el-descriptions-item>
                        <el-descriptions-item label="联系人">{{enterpriseInfo.contactName}}</el-descriptions-item>
                        <el-descriptions-item label="联系人电话">{{enterpriseInfo.contactPhone}}</el-descriptions-item>
                        <el-descriptions-item v-if="enterpriseInfo.bankName!=null" label="开户银行">{{enterpriseInfo.bankName}}</el-descriptions-item>
                        <el-descriptions-item  v-if="enterpriseInfo.bankCode!=null" label="开户银行账号">{{enterpriseInfo.bankCode}}</el-descriptions-item>
                        <el-descriptions-item label="状态">
                            <span v-if="enterpriseInfo.approveStatus=='no'"><el-tag size="small" type="info">资料已保存，尚未提交审核</el-tag></span>
                            <span v-if="enterpriseInfo.approveStatus=='approve'"><el-tag size="small">资料已提交，正在审核中，请稍等</el-tag></span>
                            <span v-if="enterpriseInfo.approveStatus=='success'"><el-tag size="small" type="success">审核已通过</el-tag></span>
                            <span v-if="enterpriseInfo.approveStatus=='fail'"><el-tag size="small" type="danger">未通过,{{enterpriseInfo.remark}}</el-tag></span>
                        </el-descriptions-item>
                        <el-descriptions-item label="企业地址">
                            {{enterpriseInfo.enterpriseAddress}}
                        </el-descriptions-item>
                    </el-descriptions>
                    <div class="hhr" style="margin-top: 20px;"></div>
                    <el-descriptions title="企业附件" :column="2" size="medium" border>
                        <el-descriptions-item label="企业营业执照" labelClassName="customFixWidthLabel">
                            <el-image v-if="enterpriseInfo.businessLicensePicture"
                                    :preview-src-list="enterpriseInfo.businessLicensePicture?[enterpriseInfo.businessLicensePicture]:[]"
                                    style="width: 100px; height: 100px"
                                    :src="enterpriseInfo.businessLicensePicture"
                                    fit="fit"></el-image>
                        </el-descriptions-item>
                    </el-descriptions>
                    <div class="subfooter">
                         <div v-if="enterpriseInfo.approveStatus=='no'">
                             <el-button type="primary" @click="sureCommit">确认提交</el-button>
                             <el-button type="primary" style="margin-left: 20px" @click="reModify(enterpriseInfo)">再次修改</el-button>
                         </div>
                        <div v-if="enterpriseInfo.approveStatus=='approve'">
                             <el-button type="primary" @click="cancel">撤销提交</el-button>
                         </div>
<!--                        <div v-if="enterpriseInfo.approveStatus=='success'">-->
<!--                            <span style="color: red;">恭喜您，已通过审核，您可以参加竞标了</span>-->
<!--                        </div>-->
                        <div v-if="enterpriseInfo.approveStatus=='fail'">
<!--                            <el-button type="primary" @click="sureCommit">请重新提交资料</el-button>-->
                            <el-button type="primary" style="margin-left: 20px" @click="reModify(enterpriseInfo)">再次修改</el-button>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <el-button type="primary" @click="addEnterprise">{{showText}}</el-button>
                    <div class="hhr"></div>
                    <el-empty description="没有添加过企业认证信息"></el-empty>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    import BreadHeader from "@/components/breadHeader/BreadHeader";
    import http from "@/utils/http";
    import api from "@/utils/api";
    import common from "@/commFunction/common";

    export default {
        inject:['reload'],
        name: "Enterprise",
        data(){
            return{
                isEnterpriseShow:true,
                enterpriseInfo:'',
                showText:'',
            }
        },
        components: {
            BreadHeader
        },
        mounted() {
          // console.log("加载企业数据");
          this.getUserInfo();
        },
        methods:{
            addEnterprise(){
                this.$router.push("/main/addEnterprise")
            },
            reModify(enterprise){
                let enterpriseId=enterprise.enterpriseId;
               this.$router.push("/main/addEnterprise/"+enterpriseId)
            },
            getUserInfo(){
                let ld=common.showDialog(".customContainer")
                http.get(api.userAccount,{}).then(res=>{
                    // console.log("enterprise加载结果:"+JSON.stringify(res))
                    let data=res.data;
                    if(data.code==200){
                        let users=data.users;
                        if(users){
                            let enterpriseId=users.enterpriseId;
                            // console.log("获取的企业id"+enterpriseId)
                            if(enterpriseId==""||enterpriseId==undefined||enterpriseId==null||enterpriseId=="0"){
                                // console.log(enterpriseId==""?"空":"不为空")
                                this.isEnterpriseShow=false;
                                this.showText='添加企业认证';
                            }
                            else{
                               //从资料里面获取状态
                                this.isEnterpriseShow=true;
                                this.getEnterpriseInfo(enterpriseId);
                            }

                        }

                    }
                }).catch(erro=>{
                    this.$message.error(erro)
                });
                common.closeDialog(ld);
            },
            getEnterpriseInfo(id){
                if(id&&id!="0"&&id.length==19){
                    let requestApi=api.getEnterpriseInfo+id;
                    // console.log("提交的参数"+requestApi)
                    http.get(requestApi,{}).then(res=>{
                      //   console.log("获取企业的信息:"+JSON.stringify(res))
                        let data=res.data;
                        if(data.code==200){
                             // console.log("企业的信息:"+JSON.stringify(data))
                             //企业信息中获取企业验证信息
                             this.enterpriseInfo=data.data;
                             let authStatus=data.data.approveStatus;
                             //获取菜单的企业资料
                            let enterpriseId=data.data.enterpriseId;



                        }
                        // if(data.code==401){
                        //     this.$message.error("令牌失效，请重新登录!")
                        //     setTimeout(()=>{
                        //        localStorage.removeItem("token");
                        //        this.$router.push("/login")
                        //     },5000)
                        // }
                    }).catch(erro=>{
                        this.$message.error(erro)
                    });

                }
            },
            //确认资料无误，确认提交
            sureCommit(){
                let url=api.beSure+this.enterpriseInfo.enterpriseId;
                http.get(url,{}).then(res=>{
                    // console.log("参数 ："+JSON.stringify(res))
                    let data=res.data;
                    if(data.code==200){
                        this.$message.success("资料提交成功，请等待审核......");
                        this.reload();

                    }
                    else{
                        this.$message.error(data.msg)
                    }
                }).catch(err=>{
                    // console.log(err)
                })

            },
            //撤销
            cancel(){
                let url=api.becancel+this.enterpriseInfo.enterpriseId;
                http.get(url,{}).then(res=>{
                    // console.log("参数 ："+JSON.stringify(res))
                    let data=res.data;
                    if(data.code==200){
                        this.$message.success("资料撤销成功......");
                        this.reload();
                    }
                    else{
                        this.$message.error(data.msg)
                    }
                }).catch(err=>{
                    // console.log(err)
                })
            },
            //未通过重新提交



        }

    }
</script>

<style>
    .customFixWidthLabel{
        width: 200px;
    }
</style>

<style scoped>

.subfooter{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: center;
}
    .successres{
        color: red;
        position: relative;
        top: 20px;
        left: 80px;
    }
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
