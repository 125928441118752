<template>
  <div id="app">
    <router-view  v-if="isShow"/>
  </div>
</template>

<script>

import {isMoney} from "@/utils/validate";

export default {
  name: 'App',
  provide(){
    return{
      reload:this.reload
    }
  },
  created() {
    let isMobile = window.matchMedia("(max-width: 425px)").matches;
    // console.log("是否是手机"+isMobile);
    if(isMobile){
      this.$router.push("/NoSupport")
    }

  },

  data(){
    return{
      isShow:true
    }
  },
  methods:{
    reload(){
      this.isShow=false;
      this.$nextTick(()=>{
        this.isShow=true
      })
    }
  }
}
</script>

<style>
  html,body,#app{
    height: 100%;
    margin: 0;
    padding: 0;
  }
</style>
