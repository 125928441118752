import { Loading  } from "element-ui";
export default{
    showDialog(target){
        let ld=Loading.service({
            lock: true,//同v-loading的修饰符
            text: '拼命加载中..',//加载文案
            backgroundColor: 'rgba(55,55,55,0.4)',//背景色
            spinner: 'el-icon-loading',//加载图标
            target: document.querySelector(target)//loading需要覆盖的DOM节点，默认为body
        });
        return ld;
    },
    //公共方法
    closeDialog(target){
        setTimeout(()=>{
            target.close();
        },500)
    }

}