<template>
    <div class="customContainer">
        <el-card class="box-card customContainer">
            <div slot="header" class="clearfix">
                <bread-header jup-path="/main/bidding" jup-page="报价列表" />
            </div>
           <span>项目详情</span>
         <el-divider></el-divider>
            <el-descriptions>
                <el-descriptions-item label="计划编号">{{parentForm.pro_plan_num}}</el-descriptions-item>
                <el-descriptions-item label="计划标题">{{parentForm.pro_plan_title}}</el-descriptions-item>
<!--                <el-descriptions-item label="客户名称">{{parentForm.customer_name}}</el-descriptions-item>-->
<!--                <el-descriptions-item label="支付方式">-->
<!--                    <div v-if="parentForm.pay_type==1">现款现货</div>-->
<!--                    <div v-if="parentForm.pay_type==2">短赊</div>-->
<!--                    <div v-if="parentForm.pay_type==3">长赊</div>-->
<!--                </el-descriptions-item>-->
                <el-descriptions-item label="报价开始时间">
                    <i class="el-icon-time"/>
                    {{parentForm.createtime}}
                </el-descriptions-item>
                <el-descriptions-item label="报价截止时间">
                    <i class="el-icon-time"/>
                    {{parentForm.closing_date}}
                </el-descriptions-item>
            </el-descriptions>
            <el-divider></el-divider>
            <span>需求明细</span>
            <el-divider></el-divider>
            <el-table :data="tableData" border style="width: 100%;" :header-cell-style="tableHeaderCellStyle">
                <el-table-column prop="biddingTag" label="报价状态">
                    <template slot-scope="scope">
                        <el-tag type="danger" v-if="scope.row.biddingTag==1" size="mini">已报价</el-tag>
                        <el-tag v-else size="mini">未报价</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="product_name" label="品名"/>
                <el-table-column prop="textofmate" label="材质"/>
                <el-table-column prop="specification" label="规格"/>
                <el-table-column prop="quantity" label="数量" width="70"/>
                <el-table-column prop="product_unit" label="单位" width="80">
                    <template slot-scope="scope">
                     {{scope.row.product_unit==1?"吨":"卷"}}
                    </template>
                </el-table-column>
                <!---weight_method-->
                <el-table-column prop="weight_method" label="计重方式" width="80">
                    <template slot-scope="scope">
                        <div v-if="scope.row.weight_method==1">理记</div>
                        <div v-if="scope.row.weight_method==2">磅记</div>
                    </template>
                </el-table-column>
                <el-table-column prop="demandorigin" label="交货地点"/>
                <el-table-column prop="expedeliverydate" label="期望交货期"/>
                <el-table-column prop="remark" label="备注"/>
                <el-table-column prop="createtime" label="创建时间"/>


                <el-table-column prop="price" label="报价"  fixed="right" width="120">
                    <template slot-scope="scope">
<!--                        {{scope.row.biddingTag}}-->
                        <el-input v-model="scope.row.price"  :disabled="scope.row.biddingTag==1?true:false"  size="mini" prefix-icon="el-icon-edit-outline" placeholder="价格" clearable  style="color: red"/>
                    </template>
                </el-table-column>

                <el-table-column prop="inputcount" fixed="right" autosize label="预报数量"  width="155">
                    <template slot-scope="scope">
                        <el-input-number size="mini"
                                         :disabled="scope.row.biddingTag==1?true:false"
                                         v-model="scope.row.inputcount.value"
                                         :min="0"
                                         :max="scope.row.inputcount.maxvalue"
                        ></el-input-number>
                    </template>
                </el-table-column>

<!--                <el-table-column prop="uploadImages" fixed="right" autosize label="质保书"  width="65">-->
<!--                    <template slot-scope="scope">-->
<!--                        &lt;!&ndash;                        <i class="el-icon-upload"    style="margin-left:5px;font-size: 30px;"   @click="openDialog(scope.row)"></i>&ndash;&gt;-->
<!--                        <el-badge style="cursor:pointer;" :value="scope.row.fileList?scope.row.fileList.length:0" class="item" type="primary">-->
<!--                            <i class="el-icon-upload" style="margin-left:5px;font-size: 30px;"  :disabled="scope.row.biddingTag==1?true:false"  @click="openDialog(scope.row)"></i>-->
<!--                        </el-badge>-->
<!--                    </template>-->
<!--                </el-table-column>-->

                <el-table-column prop="joinRemark" fixed="right" autosize label="报价备注" width="150">
                        <template slot-scope="scope">
                            <el-input size="mini" :disabled="scope.row.biddingTag==1?true:false" type="textarea" :rows="2" autosize v-model="scope.row.joinRemark" clearable/>
                        </template>
                </el-table-column>

                <el-table-column prop="goods_address" fixed="right" autosize label="装货地点" width="120">
                    <template slot-scope="scope">
                        <el-input size="mini" :disabled="scope.row.biddingTag==1?true:false" type="textarea" :rows="2" autosize v-model="scope.row.goods_address" clearable/>
                    </template>
                </el-table-column>

                <el-table-column  label="操作">
                    <template slot-scope="scope">
                        <el-popover
                                placement="left"
                                trigger="click"
                        >
                            <el-descriptions  :title="scope.row.product_name+scope.row.specification"  border :column="1">
                                <el-descriptions-item label="是否含税" labelClassName="customFixWidthLabel">
                                    <el-switch size="mini"
                                               :disabled="scope.row.biddingTag==1?true:false"
                                               v-model="scope.row.sup_is_tax"
                                               active-text="不含税"
                                               inactive-text="含税"
                                               inactive-value="2"
                                               active-value="1"
                                    >
                                    </el-switch>
                                </el-descriptions-item>
<!--                                <el-descriptions-item label="预报数量" labelClassName="customFixWidthLabel">-->
<!--                                    <el-input-number size="mini"-->
<!--                                                     :disabled="scope.row.biddingTag==1?true:false"-->
<!--                                                     v-model="scope.row.inputcount.value"-->
<!--                                                     :min="0"-->
<!--                                                     :max="scope.row.inputcount.maxvalue"-->
<!--                                    ></el-input-number>-->
<!--                                </el-descriptions-item>-->

                                <el-descriptions-item label="质保书" labelClassName="customFixWidthLabel">
                                    <el-badge v-if="scope.row.biddingTag==0" style="cursor:pointer;" :value="scope.row.fileList?scope.row.fileList.length:0"  class="item" type="primary">
                                        <i class="el-icon-upload" style="margin-left:5px;font-size: 30px;"   @click="openDialog(scope.row)"></i>
                                    </el-badge>
                                    <el-tag v-else type="info">已报价</el-tag>
                                </el-descriptions-item>

                                <el-descriptions-item label="生产厂家" labelClassName="customFixWidthLabel">
                                    <el-input size="mini" :disabled="scope.row.biddingTag==1?true:false"  v-model="scope.row.manufactorname" clearable/>
                                </el-descriptions-item>

<!--                                <el-descriptions-item label="报价备注" labelClassName="customFixWidthLabel">-->
<!--                                    <el-input size="mini" :disabled="scope.row.biddingTag==1?true:false" type="textarea" :rows="2" autosize v-model="scope.row.joinRemark" clearable/>-->
<!--                                </el-descriptions-item>-->
                            </el-descriptions>
                            <el-link type="primary" slot="reference">更多>></el-link>
                        </el-popover>
                    </template>
                </el-table-column>





            </el-table>
            <div style="text-align:center">
                <el-button  type="primary" style="margin-top: 20px;width: 20%;" @click="joinPrice" :disabled="canUsed">我要报价</el-button>
            </div>
        </el-card>
        <warranty-book ref="modifyDialog" @receive="receive"></warranty-book>
        <bidding-detail-view ref="beSureDialog"></bidding-detail-view>


    </div>
</template>

<script>
    import BreadHeader from "@/components/breadHeader/BreadHeader";
    import {SteelUnit} from '../../utils/dataconfig'
    const producelist=[
        {
            //品名
            "product_name": "热轧普碳卷",
            //材质
            "textofmate": "Q355C",
            //规格
            "specification": "8.00*1500*C",
            //数量
            "quantity": 60,
            //单位
            "product_unit": "1",
            //计重方式
            "weightmetohd": "2",
            //需求产地
            "demandorigin": "攀钢，柳钢",
            //期望交货期
            "expedeliverydate": "2022-03-31",
            //备注
            "remark": "厚度正公差交货",
            //创建时间
            "createtime": "2022-03-25",
            //采购计划ID
            "pro_plan_id": 1,
            //明细状态
            "status": 1,

            "id": 1,
            "feedbackPrice": 0.00,
        }
    ];
    import {isMoney} from '../../utils/validate';
    import common from "@/commFunction/common";
    import http from "@/utils/http";
    import api from "@/utils/api";
    import {parseTime} from "@/utils/aryee";
    import {MessageBox} from "element-ui";
    import router from "@/router";
    import WarrantyBook from "@/components/bidding/WarrantyBook";
    import BiddingDetailView from "@/components/bidding/BiddingDetailView";
    export default {
        name: "BiddingDetail",
        components: {
            BreadHeader,
            WarrantyBook,
            BiddingDetailView
        },
        mounted() {
            let id=this.$route.params.id;
            // // console.log("获取到得的参数:"+id)
            this._initParentId(id);
            this._initDetail(id);
            this.getEnterpriseInfo();
        },
        data(){
            return{
                pro_plan_num:this.$route.params.id,
                tableData:[],
                canUsed:false,
                parentForm:{},
                approveStatus:'',
                dialogImageUrl:'',
                dialogVisible: false,
                disabled: false,
            }
        },
        // watch:{
        //     tableData:{
        //         handler(newObj){
        //             let falg=true;
        //             let resultArray=new Array();
        //             if(newObj.constructor === Array){
        //                 newObj.forEach((v)=>{
        //                     let id=v['id'];
        //                     let prise=v['price'];
        //                     let productName=v['product_name'];
        //                     if(!isMoney(prise)){
        //                         let obj=new Object();
        //                         obj.id=id;
        //                         obj.value=false;
        //                         resultArray.push(obj);
        //                       //  this.$message.error("请输入"+productName+"正确的价格.")
        //                       //  this.canUsed=true;
        //                      //   falg=false;
        //                     }
        //                 });
        //                 // if(falg) {
        //                 //     this.canUsed = false;
        //                 // }
        //             }
        //             //根据输入的标价最低只有一个
        //             if(resultArray.length>0) {
        //                 for (let i = 0; i <resultArray.length;i++){
        //                     let fistBoolean=resultArray[0];
        //                     let resBoolean=resultArray[i];
        //                     if(fistBoolean||resBoolean){
        //
        //                     }
        //                 }
        //             }
        //
        //         },
        //         deep:true
        //     },
        // },
        methods:{
            receive(from){
              //console.log("收到的表单项:"+JSON.stringify(from));
              //重新替换表单值
              let id=from.id;
              let tableData=this.tableData;
              if(tableData.length>0){
                  for(let i=0;i<tableData.length;i++){
                      if(id==tableData[i].id){
                          tableData.splice(i,1,from);
                      }
                  }
              }
            },
            openDialog(row){
              //  console.log("传入的信息:"+JSON.stringify(row))
                this.$nextTick(()=>{
                    this.$refs.modifyDialog.WarrantyBookDialogVisible(row);
                });
            },
            handleRemove(file) {
                console.log(file);
            },
            handlePictureCardPreview(file) {
             //   console.log("出发这里")
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleDownload(file) {
                console.log(file);
            },
            joinPrice(){
                let array=this.tableData;
                let pushArray=new Array();
                //获取表单数据
            //     console.log("表单数据44444"+JSON.stringify(array))
                if(array&&array.length>0) {
                    for (let i=0;i<array.length;i++){
                        let id=array[i].id;
                        let price=array[i].price;
                        let detailRemark=array[i].joinRemark;
                        let goods_address=array[i].goods_address;
                        let product_name=array[i].product_name;
                        let textofmate=array[i].textofmate;
                        let specification=array[i].specification;
                        let product_unit=array[i].product_unit;
                        let is_tax=array[i].sup_is_tax;
                        let amount=array[i].inputcount.value;
                        let manufacturer=array[i].manufactorname;
                        let weight_method=array[i].weight_method;
                        let fileList=array[i].fileList
                        if(price){
                            if(isMoney(price)){

                                if(goods_address){
                                    let obj = {
                                        price: price,
                                        detail_id: id,
                                        is_tax: is_tax,
                                        amount: amount,
                                        product_name: product_name,
                                        textofmate: textofmate,
                                        specification: specification,
                                        product_unit: product_unit,
                                        weight_method: weight_method,
                                        goods_address: goods_address

                                    }
                                    if (detailRemark) {
                                        obj.remark = detailRemark
                                    }
                                    if (manufacturer) {
                                        obj.manufacturer = manufacturer
                                    }
                                    if (fileList && fileList.length > 0) {
                                        obj.qualityPash = fileList
                                    }
                                    pushArray.push(obj);
                                }
                                else{
                                    this.$message.error("请您输入装货地点");
                                    return;
                                }
                            }
                            else{
                                this.$message.error(product_name+"输入的不是正确的价格");
                                return;
                            }
                        }
                    }
                 }
                 //准备参数
                if(pushArray&&pushArray.length>0){
                   //  console.log("提交的参数:"+JSON.stringify(pushArray))
                    if(this.approveStatus=='success'){
                        //发送到后台
                        let enterpriseId=localStorage.getItem('enterpriseId');
                        let enterpriseName=localStorage.getItem('enterpriseName');
                        if(enterpriseId&&enterpriseName){
                            //更改 account_id:'123',
                            for(let i=0;i<pushArray.length;i++){
                                pushArray[i].account_id=enterpriseId;
                                pushArray[i].account_name=enterpriseName;
                            }
                            //质保书验证
                            //拿id取值比较
                            // for(let i=0;i<pushArray.length;i++){
                            //     let detail_id=pushArray[i].detail_id;
                            //      for(let j=0;j<array.length;j++){
                            //          if(detail_id==array[j].id){
                            //              let produceName=array[j].product_name;
                            //              let price=pushArray[i].price;
                            //              let qualityPash=pushArray[i].qualityPash;
                            //              if(price){
                            //                //  console.log(""+j+"位置"+produceName+"价格 ："+price)
                            //                  if(!qualityPash||qualityPash.length==0){
                            //                      this.$message.error(produceName+"必须要有质保书")
                            //                      return;
                            //                  }
                            //              }
                            //          }
                            //      }
                            //
                            // }
                            //移除属性
                            // for(let i=0;i<pushArray.length;i++){
                            //     delete pushArray[i].product_name;
                            // }
                            //将质保书转成一个string
                            for(let i=0;i<pushArray.length;i++){
                                let qualityPashArray= pushArray[i].qualityPash;
                                let qualityPashtemp=new Array();
                                if(qualityPashArray&&qualityPashArray.length>0){
                                    for(let j=0;j<qualityPashArray.length;j++){
                                        let url=qualityPashArray[j].url;
                                        // if(qualityPashArray.length==1) {
                                        //     qualityPashtemp = qualityPashtemp + url+',';
                                        // }
                                        // else{
                                         //   qualityPashtemp = qualityPashtemp + url+',';
                                     //  }
                                        qualityPashtemp.push(url);

                                    }
                                  //  pushArray[i].qualityPash=qualityPashtemp.substr(0,qualityPashtemp.lastIndexOf(',')).trim();
                                    pushArray[i].qualityPash=qualityPashtemp;

                                }
                                // else{
                                //     delete pushArray[i].qualityPash;
                                // }

                            }


                            //准备提交数据
                             //console.log("数据:"+JSON.stringify(pushArray))
                            //显示预览对话框
                            this.$refs.beSureDialog.openBiddingBeSureDialogVisible(pushArray);
                            // http.put(api.supportPrise,pushArray).then(res=>{
                            //   //   console.log("服务器返回:"+JSON.stringify(res))
                            //     let data=res.data;
                            //     if(data.code==200){
                            //         this.$message.success('竞标成功');
                            //         this.$router.push("/main/bidding")
                            //     }
                            //     if(data.code==500){
                            //         this.$message.error(data.msg);
                            //     }
                            //  //   console.log("结果:"+JSON.stringify(data))
                            // }).catch(err=>{
                            //      console.log("错误"+err)
                            // })
                        }
                    }
                    else{
                       // this.$message.error("不是合法的供应商，请验证后在竞价");
                        MessageBox.confirm("您的供应商认证未通过，通过后才可以报价!", '温馨提示', {
                            confirmButtonText: '去认证',
                            cancelButtonText: '暂时留在当前页面',
                            type: 'warning'
                        }).then(() => {
                            router.push('/main/enterprise');
                        }).catch(() => {
                          //数据收集
                        });
                        return;
                    }
                }
                else{
                    this.$message.error("必须要有一项报价的产品");
                    return;
                }
            },
            tableHeaderCellStyle({row,column,rowIndex, columnIndex}) {
                let cellStyle1;
                cellStyle1= "background-color:#EAEDF1"
                return cellStyle1;
            },
            //查找父类信息
             _initParentId(id){
                 let url=api.biddingListById+id
                 http.get(url,{}).then(res=>{
                     // console.log("父类的数据==="+JSON.stringify(res))
                     let data=res.data;
                   //  console.log("数据:"+JSON.stringify(data));
                     if(data.code==200){
                         let innerData=data.data;
                         let tempCreateTime=parseTime(new Date(innerData.createtime),'{y}-{m}-{d}');//创建时间转化
                         let tempclosing_date=parseTime(new Date(innerData.endtime),'{y}-{m}-{d}');//创建时间转化
                         this.parentForm={
                             pro_plan_num:innerData.pro_plan_num,
                             pro_plan_title:innerData.pro_plan_title,
                             customer_name:innerData.customer_name,
                             pay_type:innerData.pay_type,
                             createtime:tempCreateTime,
                             closing_date:tempclosing_date,
                         }
                       // console.log("竞价详情:"+JSON.stringify(innerData))


                     }
                     else{
                         this.$message.error(data.msg)
                     }
                 }).catch(err=>{
                     // console.log(err)
                 })

             },
            _initDetail(id){
               let ld=common.showDialog(".baseTable")
                let params={
                    pageSize:this.pageSize,
                    pageNum:this.pageNum,
                    pro_plan_id:id,
                    // status:0
                }
                http.get(api.biddingDetailList,params).then(res=>{
                    // console.log("fdsafsdaf=="+JSON.stringify(res))
                    let data=res.data;
                    if(data.code==200){
                        let innerData=data;
                        // console.log("这里的数据12322323132"+JSON.stringify(innerData))
                        let newDatas=new Array();
                        let dataArray=innerData.data;
                        let j=0;
                        for(let i=0;i<dataArray.length;i++){
                            let obj=dataArray[i];
                           // console.log("期望交货时间:"+obj.expedeliverydate)
                            if(obj.createtime) {
                                let tempCreateTime = parseTime(new Date(obj.createtime), '{y}-{m}-{d}');//创建时间转化

                                dataArray[i].createtime = tempCreateTime;
                            }
                            if(obj.expedeliverydate) {
                                let tempexpedeliverydate = parseTime(new Date(obj.expedeliverydate), '{y}-{m}-{d}');//创建时间转化
                                dataArray[i].expedeliverydate = tempexpedeliverydate;
                            }
                            let count = obj.quantity;
                            dataArray[i].inputcount={
                                maxvalue:count,
                                value:count,
                            };

                            newDatas.push(dataArray[i]);
                            if(obj.biddingTag==1){
                                j=j+1;
                            }
                        }
                        if(j==dataArray.length){
                            this.canUsed=true;
                        }
                        this.tableData=newDatas;
                        this.total=data.total;

                    }
                    else{
                        this.$message.error(data.msg)
                    }
                }).catch(err=>{
                    // console.log(err)
                })
               common.closeDialog(ld);



            },
            //获取供应商信息
            getEnterpriseInfo(){
                let id=localStorage.getItem('enterpriseId')
                if(id){
                    let requestApi=api.getEnterpriseInfo+id;
                    http.get(requestApi,{}).then(res=>{
                        // console.log("金标获取企业的信息:"+JSON.stringify(res))
                        let data=res.data;
                        if(data.code==200){
                            // console.log("企业的信息:"+JSON.stringify(data))
                            //企业信息中获取企业验证信息
                            this.enterpriseInfo=data.data;
                            this.enterpriseId=id;
                            this.approveStatus=data.data.approveStatus;
                        }
                    }).catch(erro=>{
                        this.$message.error(erro)
                    });

                }
            },
            modifyPrise(row){
                // console.log("传入的信息:"+JSON.stringify(row))
                // this.$nextTick(()=>{
                //     this.$refs.modifyDialog.openBiddingDialogVisible(row);
                // });
            },
        }
    }
</script>

<style>
    .item {
        margin-top: 10px;
    }
</style>
<style scoped>
    /*input::-webkit-input-placeholder {*/
    /*    !* WebKit browsers，webkit内核浏览器 *!*/
    /*    color: red;*/
    /*}*/
    /*input:-moz-placeholder {*/
    /*    !* Mozilla Firefox 4 to 18 *!*/
    /*    color: red;*/
    /*}*/
    /*input::-moz-placeholder {*/
    /*    !* Mozilla Firefox 19+ *!*/
    /*    color: red;*/
    /*}*/
    /*input:-ms-input-placeholder {*/
    /*    !* Internet Explorer 10+ *!*/
    /*    color: red;*/
    /*}*/

</style>

<style>
    .customFixWidthLabel{
        width: 100px;
    }
    .el-popover{
        padding: 1px!important;
    }
    .el-descriptions__title{
        margin: 10px!important;
    }
    .el-card, .el-message{
       overflow-y: auto!important;
    }
</style>
