<template>
    <div style="height: 100%">
        <el-container class="home-container">
            <el-header>
               <sub-header  @isopen="isopen" :user-info="userInfo" :imagesrc="imagesrc"></sub-header>
            </el-header>
            <el-container class="mid">
                <el-aside :width="isclose==true?'':'220px'">
                    <left-menu :isopen=isclose></left-menu>
                </el-aside>
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
<!--            <el-footer>下边</el-footer>-->
        </el-container>

    </div>
</template>

<script>
    import SubHeader from "@/components/main/subHeader/SubHeader";
    import LeftMenu from "@/components/main/leftMenu/LeftMenu";
    import http from "@/utils/http";
    import api from "@/utils/api";
    import {MessageBox} from "element-ui";
    import router from "@/router";
    export default {
        name: "Main",
        data(){
            return{
                imagesrc:'',
                isclose:false,
                userInfo:{
                    userName:'weangpou1',
                }
            }
        },
        components: {
            SubHeader,
            LeftMenu,

        },
        mounted() {
        this.checkToken();
        // //检查身份
        this.getRole()
        },
        methods:{
            isopen(status){
                this.isclose=status;
            },
            checkToken(){
                let token=localStorage.getItem('token');
                if(!token){
                    this.$router.push("/login")
                }
            },
            getRole(){
                http.get(api.userAccount,{}).then(res=>{
                    //console.log("每次页面加载结果ffff:"+JSON.stringify(res))
                    let data=res.data;
                    if(data.code==200){
                        let users=data.users;
                    }
                    if(data.code==401){
                        //只能跳一次
                        let jumpOnce=localStorage.getItem("isJump");
                        if(!jumpOnce||jumpOnce=='false') {
                            MessageBox.alert('您需要重新登录', '令牌已过期', {
                                confirmButtonText: '知道了',
                                callback: action => {
                                    localStorage.removeItem('token');
                                    router.push("/login")
                                }
                            });
                            localStorage.setItem("isJump",'true');
                        }
                    }
                }).catch(erro=>{
                   this.$message.error(erro)
                });
            }
        }

    }
</script>

<style scoped>
    .home-container{
        height: 100%;
    }
    .el-header {
        background-color: #373D41;
        display: flex;
        justify-content: space-between;
        padding-left: 0;
        align-items: center;
        color: #fff;
        font-size: 20px;
    }
    div {
        display: flex;
        align-items: center;
    }
    span{
        margin-left: 15px;
    }

    .el-main{
        background-color: #EAEDF1;
        /*padding: 0;*/
    }
    .iconfont{
        margin-right: 10px;
    }
    .toggle-button{
        background-color: #4A5064;
        font-size: 10px;
        line-height: 24px;
        color: #fff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }
    .el-divider--horizontal{
        margin:10px 0px;
    }



.mid{

}
</style>
