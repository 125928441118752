<template>
    <div class="bread">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/main' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item><a :href="path">{{currentPage}}</a></el-breadcrumb-item>
        </el-breadcrumb>

    </div>
</template>

<script>
    export default {
        name: "BreadHeader",
        props:{
            jupPage:{
                type:String,
                default:''
            },
            jupPath:{
                type:String,
                default:'/'
            }
        },
        data(){
            return{
                currentPage:this.jupPage,
                path:this.jupPath,

            }
        },
    }
</script>

<style scoped>
 .bread{
     width:100%;
     background-color:white ;
 }
</style>