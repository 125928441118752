<template>
    <div>
   图片地址:{{imgsrc}}
        <img :src="imgsrc"/>
    </div>
</template>

<script>
    import http from "@/utils/http";
    import api from "@/utils/api";
    export default {
        name: "Test",
        data(){
            return{
                imgsrc:'https://file.yngy.cloud/statics/source/2022/04/13/19226269-9e8a-42fa-9d70-dd6dd9aa1843.jpg'
            }
        },
        methods: {
            uploadSuccess(y) {
                // // console.log(JSON.stringify(y))
            },
            failStatus() {
                // // console.log("失败")

            },
        }
    }
</script>

<style scoped>

</style>
