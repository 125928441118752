const prefix='/api';
//用户信息列表
const userlist=prefix+'/user/list';

const test=prefix+'/user/s';
//用户信息
const userinfo=prefix+'/user/info';
//添加新用户
const addUser=prefix+'/user/add';
//删除用户
const deleteUser=prefix+'/user/delete';
//修改信息
const editUser=prefix+'/user/edit';
//验证吗
const captcha=prefix+'/captcha';
//用户注册
const register=prefix+'/account/register';
//用户登录
const  login=prefix+'/account/login';
//注册短信短信发送
const registerSms=prefix+'/sms/tencent/register/';
//登录短信
const LoginSms=prefix+'/sms/tencent/login/';
//获取用户账号信息
const  userAccount=prefix+'/account/user/getInfo';
//标书列表
const  biddingList=prefix+'/source/suppliertask/list';
//对采购明细
const biddingDetail=prefix+'/source/supproplandetail/getsupplierProPlanDetail/';
//采购明细列表
//const biddingDetailList=prefix+'/source/supproplandetail/list';
//新接口
const biddingDetailList=prefix+'/source/supproplandetail/listbidding';
//供应商竞价列表单个
const biddingListById=prefix+'/source/supplierproplan/getSupProPlan/';
//添加竞价
const supportPrise=prefix+'/source/supplierbidding/addBatch';
//我的竞价
const mybodingList=prefix+'/source/supplierbidding/getMySupplierBidding';
//const mybodingList=prefix+'/source/supplierbidding/getMySupplierBidding?status=1';
//添加用户审核
const userAuth=prefix+'/account/source/enterprise';
//获取企业的信息
const getEnterpriseInfo=prefix+"/account/source/enterprise/";
//确认提交
const beSure=prefix+'/account/source/enterprise/approve/';
//撤销提交
const becancel=prefix+'/account/source/enterprise/cancel/';
//供应商数据统计
const datatotal=prefix+'/source/datatotal/list';
//首页显示
const homeIndex=prefix+'/source/supplierproplan/listIndex';
//首页详情
const homeDetail=prefix+'/source/supproplandetail/listIndex/';
//供应商下载合同
const downContract=prefix+'/source/suppliercontract/getcontract';
//首页详情id
const homeTitleDetail=prefix+'/source/supplierproplan/getSupProPlanIndex/';
//上传文件
const uploadFile=prefix+'/account/source/file';
//修改个人密码
const updatePasword=prefix+'/account/user/profile/updatePwd';
//修改个人资料
const updateProfile=prefix+'/account/user/profile';
//首页项目公示
const indexProgramShow=prefix+'/source/supplierproplanweb/list';





const api={
    indexProgramShow:indexProgramShow,
    downContract:downContract,
    prefix:prefix,
    homeTitleDetail:homeTitleDetail,
    homeDetail:homeDetail,
    homeIndex:homeIndex,
    mybodingList:mybodingList,
    uploadFile:uploadFile,
    updateProfile:updateProfile,
    updataPasword:updatePasword,
    datatotal:datatotal,
    becancel:becancel,
    beSure:beSure,
    supportPrise:supportPrise,
    biddingListById:biddingListById,
    biddingDetailList:biddingDetailList,
    getEnterpriseInfo:getEnterpriseInfo,
    userAuth:userAuth,
    test:test,
    biddingDetail:biddingDetail,
    biddingList:biddingList,
    userAccount:userAccount,
    LoginSms:LoginSms,
    registerSms:registerSms,
    login:login,
    register:register,
    captcha:captcha,
    editUser:editUser,
    deleteUser:deleteUser,
    addUser:addUser,
    userlist:userlist,
    userinfo:userinfo
}

export default api;
