<template>
    <div>
        <el-dialog :title="isInsert==true?'添加用户信息':'修改用户信息'" :visible.sync="dialogFormVisible">
            <el-form :model="form">
                <el-form-item label="用户名" :label-width="formLabelWidth">
                    <el-input v-model="form.userName" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="请输入新密码" :label-width="formLabelWidth">
                    <el-input v-model="form.passWord"  placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="请输入角色" :label-width="formLabelWidth">
                    <el-input v-model="form.role"  placeholder="请输入角色"></el-input>
                </el-form-item>
                <el-form-item label="请输入电话" :label-width="formLabelWidth">
                    <el-input v-model="form.phone"  placeholder="请输入电话"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button v-if="isInsert==true" type="primary" @click="submit(form)">确 定</el-button>
                <el-button v-if="isInsert==false" type="primary" @click="edit(form)">修改</el-button>
            </div>
        </el-dialog>

    </div>



</template>

<script>
    import http from "@/utils/http";
    import api from "@/utils/api";

    export default {
        name: "addOrModifyUser",
        data(){
            return{
                dialogFormVisible:false,
                form: {
                    userName: '',
                    passWord:'',
                    role:'',
                    phone:''

                },
                isInsert:true,
                formLabelWidth: '100px'
            }
        },
        methods:{
            openDialog1Visible(from){
                if(from){
                    // // console.log("这里的值:"+JSON.stringify(from))
                    this.dialogFormVisible = true;
                    this.form=from
                    this.isInsert=false;

                }
                else {
                    this.dialogFormVisible = true;
                    this.form = {};
                    this.isInsert=true;
                }
            },
            submit(from){
                // console.log("表单里的对象:"+JSON.stringify(from));
                    if(from) {
                        http.post(api.addUser, from).then(res => {
                            let data = res.data;
                            if (data.code == 200) {
                                // console.log("添加用户数据 ：" + JSON.stringify(data))
                                let innerData = data.data;
                                // console.log("添加用户数据：" + JSON.stringify(innerData))
                                this.$message({
                                    message: '操作成功',
                                    type: 'success'
                                });
                                this.$emit('isUpdate', true) //通过$emit触发父组件

                            } else {
                                this.$message.error(data.msg)
                            }

                        }).catch(err => {
                            // console.log(err)
                            this.$emit('isUpdate', false) //通过$emit触发父组件
                        })
                    }
                    else {
                        this.$emit('isUpdate', false) //通过$emit触发父组件
                    }
                this.dialogFormVisible=false;



            },
            edit(from){
                // console.log("要修改的对象值:"+JSON.stringify(from))
                if(from) {
                    http.post(api.editUser, from).then(res => {
                        let data = res.data;
                        if (data.code == 200) {
                            this.$message({
                                message: '操作成功',
                                type: 'success'
                            });
                            this.$emit('isUpdate', true) //通过$emit触发父组件

                        } else {
                            this.$message.error(data.msg)
                        }

                    }).catch(err => {
                        // console.log(err)
                        this.$emit('isUpdate', false) //通过$emit触发父组件
                    })
                }
                else {
                    this.$emit('isUpdate', false) //通过$emit触发父组件
                }
                this.dialogFormVisible=false;
            },


        }
    }
</script>

<style scoped>

</style>
