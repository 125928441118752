<template>
    <div class="container">
        <div :class="diffTitle==false?'login_header_box_active':'login_header_box'">
            <div :class="diffTitle==false?'subcontainer_active':'subcontainer'">
<!--                <a class="login_title" href="/" target="_self">-->
<!--                    <img src="../../assets/img/yngylogo.png" alt=""  style="max-width: 220px;max-height: 50px;">-->
<!--                    <span></span>-->
<!--                    <p>云南钢友招采系统</p>-->
<!--                </a>-->
                <home-header :style="{backgroundColor:'#fff'}"  :is-show-content=diffTitle></home-header>
            </div>
        </div>
        <div class="login_main_box">
            <div class="login_way_box">
                <div class="login_form active" id="tab1_content" style="padding: 30px 30px;">
                    <div class="title">用户登录</div>
                    <el-form :model="loginForm" :rules="loginRules" ref="loginForm">
                        <el-form-item class="item" prop="username">
                            <el-input prefix-icon="el-icon-user" v-model="loginForm.username" placeholder="请输入账号" clearable></el-input>
                        </el-form-item>
                        <el-form-item class="item" prop="password">
                            <el-input prefix-icon="el-icon-key"  placeholder="请输入密码" v-model="loginForm.password"  clearable show-password></el-input>
                        </el-form-item>
                        <el-form-item class="item yzm" prop="imagecode">
                            <div class="yzm">
                                <el-input prefix-icon="el-icon-female" style="width:90%;" v-model="loginForm.imagecode" placeholder="请输入验证码" clearable></el-input>
                                <el-image @click="changeImage" style="width: 250px; margin-left: 5%; height: 38px;border-radius: 5px;" :src="src"/>
                            </div>
                        </el-form-item>
                        <el-form-item class="item">
                            <el-button type="primary" style="width: 100%;" @click="login('loginForm')">登录</el-button>
                        </el-form-item>
                        <el-form-item class="registerItem">
                            <el-checkbox v-model="loginForm.rememberMe" style=" float: left;" >记住账号</el-checkbox>
                            <a class="customLink" @click="register">立即注册</a>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "@/utils/http";
    import api from "@/utils/api";
    import HomeHeader from "@/components/home/HomeHeader";
    import {validateTelephone} from "@/utils/validate";
    import cryptoAES from "@/utils/cryptoAES";

    export default {
        name: "Login",
        components:{
            HomeHeader
        },
        data(){
            return{
                loginForm: {},
                rememberMe:false,
                //   表单验证
                loginRules: {
                    username:[{required: true, message: "请输入账号", trigger: "blur"}],
                    password:[{required: true, message: "请输入密码", trigger: "blur"}],
                    imagecode:[{required: true, message: "请输入图片答案", trigger: "blur"}],

                },
                imagePrefix:'data:image/gif;base64,',
                src:'',
                uuid:'',
                diffTitle:true //是相同页页面

            }
        },
        mounted() {
            this.yzmImage();
            let routeName=this.$route.name;
            // console.log("页面的路径 ："+routeName)
            if(routeName){
                if(routeName=='Login'){
                 this.diffTitle=false;
                }
            }
           this.rememberData();
        },
        methods:{
            rememberData(){
                if(localStorage.length>0){
                    let username = localStorage.getItem('userName');
                    let password  =localStorage.getItem('password');
                    if(username&&password) {
                        let rememberMe  =true;
                        let expasword = cryptoAES.decrypt(password);
                        this.$set(this.loginForm, 'rememberMe', rememberMe)
                        this.$set(this.loginForm, 'username', username)
                        this.$set(this.loginForm, 'password', expasword)
                    }
                }
            },
            changeImage(){
                this.yzmImage();
            },
            yzmImage(){
                http.get(api.captcha,{}).then(res=>{
                    let data=res.data;
                    if(data.code==200){
                        let imageBase=data.img;
                        let src=this.imagePrefix+imageBase;
                        this.src=src;
                        this.uuid=data.uuid;
                    }
                    else{
                        this.$message.error(data.msg)
                    }
                }).catch(err=>{
                    // console.log(err)
                })
            },
            login(from){
                this.$refs[from].validate(valid=> {
                    if (valid) {
                        let userName=this.loginForm.username;
                        let password=this.loginForm.password;
                      // console.log("验证通过")
                        let params= {
                            uuid : this.uuid,
                            userName:userName,
                            password:password,
                            captcha:this.loginForm.imagecode,
                        }
                        let rememberMe=this.loginForm.rememberMe;
                        // console.log("验证参数"+JSON.stringify(params))
                        http.post(api.login,params).then(res=>{
                            // console.log("登录参数:"+JSON.stringify(res))
                            let data=res.data;
                            if(data.code==200){
                                let token=data.data.access_token;
                                if(rememberMe==true){
                                    if(userName&&password){
                                        //最好使用加密
                                        localStorage.setItem('userName',userName);
                                        let eypass=cryptoAES.encrypt(password);
                                        localStorage.setItem('password',eypass);
                                    }
                                }
                                else{
                                    localStorage.removeItem('userName');
                                    localStorage.removeItem('password');
                                }
                                localStorage.setItem("token",token);
                                let isJump=localStorage.getItem("isJump");
                                if(isJump){
                                    localStorage.removeItem('isJump');
                                }
                                this.$router.push("/main")
                            }
                            else{
                                let msg=data.msg;
                                if(msg=='验证码错误'){
                                    this.yzmImage();
                                }
                                this.$message.error(data.msg)
                            }
                        }).catch(err=>{
                            // console.log(err)
                        })
                    }
                });
            },
            register(){
                this.$router.push("/register")
            }

        }
    }
</script>

<style scoped>
    .el-form-item__content{
        line-height:20px;
        /* position: relative; */
    font-size: 14px;
    }
    .container{
        background: #F0F2F5 url('../../assets/img/login_bg.jpg') center center no-repeat;
        background-size: cover;
        height: 100%;
        width:100%;

    }
    .subcontainer{
        width: 80%;
        padding: 0;
        margin: 0 auto;
        position: relative;
    }
    .subcontainer_active{
        width: 100%;
        padding: 0;
        margin: 0 auto;
        position: relative;
    }
    .login_header_box{
        background: rgba(255,255,255,.8);
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
    }
    login_header_box_active{
        /*background: #fff;*/
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;

    }
    .login_title{
        display: flex;
        align-items: center;
    }
    .login_title span {
        width: 2px;
        height: 18px;
        background: #adaeaf;
        margin: 0 16px;
        display: block;
    }
    .login_title p {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 3px;
        text-decoration: none;
    }
    .login_main_box {
        width: 400px;
        min-height: 315px;
        background: #ffffff;
        box-shadow: 0px 10px 24px rgb(0 0 0 / 30%);
        border-radius: 8px;
        position: absolute;
        right: 15%;
        top: 30%;
        z-index: 1;
    }
    .title{
        font-size: 18px;
        font-weight: bold;
        width: 100%;
        text-align: center;
    }
    .item{
        margin: 20px;
    }
    .registerItem{
        margin: -10px 20px;
        text-align: right;
    }
    .login_form .textbox_ui{
        position: relative;
        border-bottom: 1px solid rgba(187, 183, 183, 0.5);
    }
    .yzm{
        display: flex;
        flex-direction: row
    }
    .customLink{
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        position: relative;
        text-decoration: none;
        outline: 0;
        padding: 0;
        color: #409EFF;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
    }


</style>
