<template>
    <div class="customContainer">
        <el-card class="box-card customContainer">
            <p class="fixBottom">
                <i style="font-size: 22px;" class="el-icon-alarm-clock"></i>:{{now}}</p>
            <div >
               <p>欢迎来到,云南钢友集采系统，我们热诚为您服务:</p>
               <p>在使用系统前,您需要操作的步骤如下:</p>
                <el-steps :active="active">
                    <el-step title="填写资料" description="填写资料,企业进行认证"></el-step>
                    <el-step :title="defaultTile" :description="defaultDescription"></el-step>
                    <el-step title="参与报价" description="企业审核通过,参与报价"></el-step>
                </el-steps>
            </div>

        </el-card>
    </div>

</template>

<script>
    import {getCurrentTime} from "@/utils/DateUtils";
    import http from "@/utils/http";
    import api from "@/utils/api";

    export default {
        name: "DefaultMain",
        data(){
            return{
                now:'',
                active:1,
                defaultTile:'等待审核',
                defaultDescription:'资料填写完毕提交,等待管理员审核',
            }
        },
        mounted() {
            this.getTime();
            this.getRole();
        },
        methods:{
            //查询用户审核状态
            getRole(){
                http.get(api.userAccount,{}).then(res=>{
                    if(res) {
                        let data = res.data;
                        if (data.code == 200) {
                         let enterpriseStatus=data.enterpriseStatus;
                         if(enterpriseStatus){
                             if(enterpriseStatus=='success'){
                                 this.active=3;
                             }
                             else if(enterpriseStatus=='approve'){
                                 this.defaultTile='等待审核';
                                 this.defaultDescription='资料填写完毕提交,等待管理员审核';
                                 this.active=2;
                             }
                             else if(enterpriseStatus=='fail'){
                                 this.defaultTile='审核不通过';
                                 this.defaultDescription='请确认资料无误后再次提交,等待管理员审核';
                                 this.active=2;
                             }
                             else{
                                 //资料未空显示未提交,或者被拒接
                                 this.defaultTile='等待审核';
                                 this.defaultDescription='资料填写完毕提交,等待管理员审核';
                                 this.active=1;
                             }
                         }

                        }
                    }
                }).catch(erro=>{
                    this.$message.error(erro)
                });
            },
            getTime(){
                const timer = setInterval(() =>{
                    let time=getCurrentTime();
                    this.now=time;
                }, 1000);
                // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
                this.$once('hook:beforeDestroy', () => {
                    clearInterval(timer);
                })
            }
        }
    }
</script>

<style scoped>
  p{
      font-size: 20px;
      font-weight: bold;
  }
  .fixBottom{
      position: absolute;
      right: 0;
      margin-right: 50px;
      padding-bottom: 10px;
  }.help-content{
         text-indent:24px;
     }
</style>
