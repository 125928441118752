<template>
    <div>
        <el-menu default-active="1-4-1" class="el-menu-vertical-demo" :unique-opened=true :collapse="isopen" :default-active="$route.meta.name" >
            <el-menu-item index="0" @click="jump('')">
                <i class="el-icon-document"></i>
                <span slot="title">首页</span>
            </el-menu-item>
<!--            <el-submenu index="1">-->
<!--                <template slot="title">-->
<!--                    <i class="el-icon-location"></i>-->
<!--                    <span slot="title">用户管理</span>-->
<!--                </template>-->
<!--                <el-menu-item-group>-->
<!--                    <el-menu-item index="1-1" @click="jump('user')">用户列表</el-menu-item>-->
<!--                </el-menu-item-group>-->
<!--            </el-submenu>-->

            <el-submenu index="2">
                <template slot="title">
                    <i class="el-icon-menu"></i>
                    <span slot="title">报价管理</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="biddinglist" @click="jump('bidding')">
                        <i class="el-icon-notebook-2"></i>
                        报价列表
                    </el-menu-item>
                    <el-menu-item index="mybiddinglist" @click="jump('mybidding')">
                        <i class="el-icon-s-order"></i>
                        我的报价
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
<!--            <el-menu-item index="3">-->
<!--                <i class="el-icon-document"></i>-->
<!--                <span slot="title">合同管理</span>-->
<!--            </el-menu-item>-->
<!--            <el-submenu index="3">-->
<!--                <template slot="title">-->
<!--                    <i class="el-icon-film"></i>-->
<!--                    <span slot="title">合同管理</span>-->
<!--                </template>-->
<!--                <el-menu-item-group>-->
<!--                    <el-menu-item index="contractlist" @click="jump('contract')">-->
<!--                        <i class="el-icon-reading"></i>-->
<!--                        合同列表-->
<!--                    </el-menu-item>-->

<!--                </el-menu-item-group>-->
<!--            </el-submenu>-->
<!--            <el-menu-item index="4">-->
<!--                <i class="el-icon-s-order"></i>-->
<!--                <span slot="title">订单管理</span>-->
<!--            </el-menu-item>-->

            <el-submenu index="5">
                <template slot="title">
                    <i class="el-icon-setting"></i>
                    <span slot="title">资料管理</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="enterpriseInfo" @click="jump('enterprise')">
                        <i class="el-icon-coordinate"></i>
                        企业认证
                    </el-menu-item>
                    <el-menu-item index="personInfo" @click="jump('info')">
                        <i class="el-icon-s-custom"></i>
                        个人信息
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
<!--            <el-submenu index="6">-->
<!--                <template slot="title">-->
<!--                <i class="el-icon-s-finance"></i>-->
<!--                <span slot="title">财务管理</span>-->
<!--                </template>-->
<!--                <el-menu-item-group>-->
<!--                    <el-menu-item index="6-1">结算管理</el-menu-item>-->
<!--                    <el-menu-item index="6-2">发票管理</el-menu-item>-->
<!--                    <el-menu-item index="6-3">台账管理</el-menu-item>-->
<!--                </el-menu-item-group>-->
<!--            </el-submenu>-->
        </el-menu>
    </div>
</template>

<script>
    export default {
        name: "LeftMenu",
        props:{
          isopen:{
              type:Boolean,
          }
        },
        methods:{
            jump(value){
                if(value) {
                    this.$router.push("/main/" + value);
                }
                else{
                    this.$router.push("/main");
                }
            }
        }
    }
</script>

<style scoped>
    .el-aside {
        /*background-color: #333744;*/
        background-color: #373D41;
    }
    .el-menu{
        border-right: none;
        width: 100%;
    }
</style>
