<template>
    <div class="customContainer">
        <el-card class="box-card customContainer">
            <div slot="header" class="clearfix">
                <bread-header jup-path="/main/bidding" jup-page="报价列表" />
            </div>
            <div class="menuList">
                <div class="menuItem">
                    <el-input v-model="findTitle" style="width: 250px;" size="medium" placeholder="计划标题" prefix-icon="el-icon-date" clearable />
                    <el-input v-model="findNum" class='fixWeight' style="width: 250px;" size="medium" placeholder="计划编号" prefix-icon="el-icon-date" clearable />
<!--                    <el-input v-model="findClient" class="fixWeight" style="width: 250px;" size="medium" placeholder="客户名称" prefix-icon="el-icon-date" clearable />-->
                    <el-date-picker class="fixWeight" size="medium" style="width: 300px;"
                                    value-format="yyyy-MM-dd"
                            v-model="dateValue"
                            type="date"
                            placeholder="选择创建日期">
                    </el-date-picker>
<!--                    <el-select class="fixWeight"  size="medium" v-model="selectValue" placeholder="请选择" clearable>-->
<!--                        <el-option-->
<!--                                v-for="item in options"-->
<!--                                :key="item.value"-->
<!--                                :label="item.label"-->
<!--                                :value="item.value"-->
<!--                        >-->
<!--                        </el-option>-->
<!--                    </el-select>-->
                    <el-button class="space" type="primary" @click="find">查询</el-button>
                </div>
            </div>
            <el-divider/>
            <div class="baseTable">
                <el-table
                        :data="tableData"
                        border
                        style="width: 100%;" :header-cell-style="tableHeaderCellStyle" >
                    <el-table-column
                            prop="pro_plan_num"
                            label="计划编号"
                            width="150"
                         >
                    </el-table-column>
                    <el-table-column
                            prop="pro_plan_title"
                            label="计划标题"
                            width="150"
                            >
                    </el-table-column>
<!--                    <el-table-column-->
<!--                            prop="customer_name"-->
<!--                            label="客户名称"-->
<!--                            width="150"-->
<!--                        >-->
<!--                    </el-table-column>-->

<!--                    <el-table-column-->
<!--                            prop="customer_type"-->
<!--                            label="客户类型"-->
<!--                           >-->
<!--                        <template slot-scope="scope">-->
<!--                            <div v-if="scope.row.customer_type==1">贸易商</div>-->
<!--                            <div v-if="scope.row.customer_type==2">终端</div>-->
<!--                            <div v-if="scope.row.customer_type==3">次终端</div>-->
<!--                        </template>-->
<!--                    </el-table-column>-->

<!--                    <el-table-column-->
<!--                            prop="pay_type"-->
<!--                            label="付款方式"-->
<!--                           >-->
<!--                        <template slot-scope="scope">-->
<!--                            {{ scope.row.pay_type === 1 ?'现金':'贷款' }}-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                    <el-table-column-->
<!--                                     prop="pay_amount"-->
<!--                                     label="货款金额">-->
<!--                    </el-table-column>-->

<!--                    <el-table-column width="120"-->
<!--                                     prop="pay_percent"-->
<!--                                     label="预付比例(%)">-->
<!--                    </el-table-column>-->

<!--                    <el-table-column-->
<!--                            prop="closing_date"-->
<!--                            label="收款日期"-->
<!--                            >-->
<!--                    </el-table-column>-->
                    <el-table-column
                            prop="delivery_time"
                            label="交货时间"
                            >
                    </el-table-column>
<!--                    <el-table-column-->
<!--                            prop="quotedprice_time"-->
<!--                            label="报价时间"-->
<!--                           >-->
<!--                    </el-table-column>-->

<!--                    <el-table-column-->
<!--                            prop="account_id"-->
<!--                            label="用户编号"-->
<!--                         >-->
<!--                    </el-table-column>-->

                    <el-table-column
                                     prop="remark"
                                     label="备注">
                    </el-table-column>

                    <!--//否删除标记-->
<!--                    <el-table-column-->
<!--                            prop="del_flag"-->
<!--                            label="有效"-->
<!--                           >-->
<!--                        <template slot-scope="scope">-->
<!--                          <el-tag v-if="scope.row.del_flag === 0">有效</el-tag>-->
<!--                          <el-tag v-else>无效</el-tag>-->
<!--                        </template>-->
<!--                    </el-table-column>-->


                    <el-table-column
                                      prop="createtime"
                                      label="创建时间">
                    </el-table-column>

                    <el-table-column
                            prop="endtime"
                            label="截止时间">
                    </el-table-column>


                    <el-table-column
                                     prop="status"
                                     label="任务状态">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status != 4">竞价中</el-tag>
<!--                            <el-tag v-else-if="scope.row.del_flag === 1">已截至</el-tag>-->
                            <el-tag v-else type="info">已截止</el-tag>
                        </template>

                    </el-table-column>


                    <el-table-column
                            label="操作">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.status != 4" @click="lookPlanDetail(scope.row)" type="text" size="small">我要报价</el-button>
                            <el-button v-else  type="text" size="small" style="color: #E6A23C;">已截止</el-button>
                        </template>
                    </el-table-column>


                </el-table>
                <el-pagination class="footerPage"
                               background
                               @current-change="handleCurrentChange"
                               layout="prev, pager, next"
                               :total="total">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
    const  monidata=[{
        //计划编号
        "pro_plan_num": "20220325001",
        //计划标题
        "pro_plan_title": "钢材一批",
        //客户名称
        "customer_name": "云南建投物流",
        //客户类型
        "customer_type": "2",
        //付款方式
        "pay_type": "1",
        //货款金额
        "pay_amount": 6000.0,
        //预付比例(%)
        "pay_percent": "20",
        //收款日期
        "closing_date": "2022-03-30",
        //交货时间
        "delivery_time": "2022-03-29",
        //报价时间
        "quotedprice_time": "2022-03-28",
        //用户ID
        "account_id": "1485498200381214722",
        //是否删除标记
        "del_flag": 0,
        //备注
        "remark": "发货速度快一点",
        //创建时间
        "createtime": "2022-03-25",
        //任务状态
        "status": 1,

        "task_status": 0,
        "task_id": 1,
        "id": 1,

    }];
    import BreadHeader from "@/components/breadHeader/BreadHeader";
    import {parseTime} from '../../utils/aryee.js';
    import common from "@/commFunction/common";
    import http from "@/utils/http";
    import api from "@/utils/api";
    export default {
        name: "Bidding",
        components: {
            BreadHeader
        },
        mounted() {
           // let t= parseTime('2022-03-28T08:00:00','{y}-{m}-{d}');
            // // console.log("时间"+t);
            this._initData();
        },
        data() {
            return {
                options: [{
                    value: '1',
                    label: '有效'
                }, {
                    value: '2',
                    label: '无效',
                }],
                findTitle:'',
                findNum:'',
                findClient:'',
                findValue: '' ,
                selectValue: '' ,
                tableData:[],
                pageSize:10,
                pageNum:1,
                total:0,
                dateValue:''
            }
        },
        methods:{
            tableHeaderCellStyle({row,column,rowIndex, columnIndex}) {
                let cellStyle1;
                cellStyle1= "background-color:#EAEDF1"
                return cellStyle1;
            },
            find(){
                //获取关键字参数
                let findTitle=this.findTitle;
                let findNum=this.findNum;
                let dateValue=this.dateValue;

                let params=new Object();
                if(findTitle!=''&&findTitle!=null&&findTitle!=undefined){
                    params.pro_plan_title=findTitle;
                }
                if(findNum!=''&&findNum!=null&&findNum!=undefined){
                    params.pro_plan_num=findNum;
                }
                if(dateValue!=''&&dateValue!=null&&dateValue!=undefined){
                    params.beginTime=dateValue;
                    params.endTime=dateValue;
                }

                //console.log("提交参数:"+JSON.stringify(params))
                this._initData(params);
            },
            dateFormat(date){
                return parseTime(date,'{y}-{m}-{d}');
            },
            _initData(params){
                let ld=common.showDialog(".baseTable")
                if(!params){
                    params={};
                }
                //传入固定参数
                params.status=0;
                params.sup_proplan_status=1;
                params.pageNum=this.pageNum;
                params.pageSize=this.pageSize;

               // console.log("这里传入的参数:"+JSON.stringify(params))
                http.get(api.biddingList,params).then(res=>{
                 //console.log("获取的集采数据222==============:"+JSON.stringify(res));
                    if(res) {
                        let data = res.data;
                        if (data.code == 200) {
                            let newDatas = new Array();
                            let dataArray = data.rows;
                            for (let i = 0; i < dataArray.length; i++) {
                                let obj = dataArray[i];
                                let tempCreateTime = parseTime(obj.createtime, '{y}-{m}-{d}');//创建时间转化
                                let tempEndTime = parseTime(obj.endtime, '{y}-{m}-{d}');//创建时间转化
                                let tempclosing_date = parseTime(obj.closing_date, '{y}-{m}-{d}');//创建时间转化
                                let tempquotedprice_time = parseTime(obj.quotedprice_time, '{y}-{m}-{d}');//创建时间转化
                                let tempdelivery_time = parseTime(obj.delivery_time, '{y}-{m}-{d}');//创建时间转化
                                dataArray[i].createtime = tempCreateTime;
                                dataArray[i].endtime = tempEndTime;
                                dataArray[i].closing_date = tempclosing_date;
                                dataArray[i].quotedprice_time = tempquotedprice_time;
                                dataArray[i].delivery_time = tempdelivery_time;
                                newDatas.push(dataArray[i]);
                            }
                            this.tableData = newDatas;
                            this.total = data.total;
                        }
                    }

                }).catch(err=>{
                     console.log(err)
                })

                common.closeDialog(ld);
            },
            //分页
            handleCurrentChange(currentPage){
                // console.log("你点击了第几页"+currentPage)
                //设置页面参数
                this.pageNum=currentPage;
                this._initData();
            },
            lookPlanDetail(row){
                this.$router.push("/main/biddingDetail/"+row.id)
            },

        },

    }
</script>

<style scoped>
  .el-table__header tr.el-table__header th {
      padding: 0;
     height: 30px;
     line-height: 30px;
    }
    .menuList{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        font-size: 14px;
    }
    .menuList .menuItem span{
        width: 10%;
    }
    .menuList .menuItem{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .menuList .menuItem .space{
        margin: 0px 5px;
    }
    .baseTable{
        margin-top: 5px;
    }
    .footerPage{
        float: right;
        margin-bottom: 50px;
        margin-top: 10px;

    }
    .fixWeight{
        margin-left: 10px;
    }
</style>
