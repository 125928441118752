<template>
    <div class="avatar">
<!--        <i style="font-size: 40px;position: relative;top: 10px;right: 30px;color:white;"  class="el-icon-s-home"  @click="toHome"></i>-->
      <el-dropdown>
      <span class="el-dropdown-link">
        <img :src="imagesrc?imagesrc:defaultsrc"/>
          <span>{{roleName}}</span>
        <i class="el-icon-arrow-down"></i>
      </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-user" @click.native="modifyPassword">修改密码</el-dropdown-item>
                <el-dropdown-item icon="el-icon-switch-button" @click.native="logout">退出</el-dropdown-item>
            </el-dropdown-menu>

        </el-dropdown>

        <!--弹出修改对话框--->
        <el-dialog title="修改密码" :visible.sync="dialogFormVisible" :close-on-click-modal=false>
            <el-form :model="form" :rules="rules" ref="modifyPassform">
                <el-form-item label="用户名" :label-width="formLabelWidth" >
                    <el-input :value="form.userName" disabled></el-input>
                </el-form-item>
                <el-form-item label="旧密码" :label-width="formLabelWidth" prop="password">
                    <el-input v-model="form.password"  placeholder="请输入旧密码" show-password clearable></el-input>
                </el-form-item>
                <el-form-item label="新密码" :label-width="formLabelWidth" prop="newpassword">
                    <el-input v-model="form.newpassword"  placeholder="请输入新密码" show-password clearable></el-input>
                </el-form-item>
                <el-form-item label="再次新密码" :label-width="formLabelWidth" prop="repassword">
                    <el-input v-model="form.repassword"  placeholder="请再次输入新密码" show-password clearable></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit('modifyPassform')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

    import http from "@/utils/http";
    import api from "@/utils/api";
    export default {
        name: "AvatarMenu",
        props:{
            userInfo:{
                userName:{
                    type: String,
                    default:''
                }
            },
            // imagesrc:{
            //     type: String,
            //     default: ''
            // }
        },
        data(){
            const validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'))
                } else if (value !== this.form.newpassword) {
                    callback(new Error('两次输入密码不一致!'))
                } else {
                    callback()
                }
            }
            return{
                imagesrc:'',
                defaultsrc:require('../../../../assets/img/avatar.png'),
                userName:'',
                dialogFormVisible:false,
                form: {},
                formLabelWidth: '100px',
                roleName:'',
                rules:{
                    password:[
                        {required: true, message: '旧密码不能为空', tirgger: 'blur'},
                    ],
                    newpassword:[
                        {required: true, message: '新密码不能为空', tirgger: 'blur'},
                    ],
                    repassword:[
                        {required: true, message: '请再次输入新密码', tirgger: 'blur'},
                        { required: true, validator: validatePass2, trigger: 'blur' }
                    ]
                },
            }
        },
        mounted() {
            //获取用户身份
           this.getRole();

        },
        watch:{
            userInfo:{
                handler:function (newObj){
                    this.userName=newObj.userName;
                    this.form={
                        userName:newObj.userName
                    }
                },
                deep:true
            },

        },
        methods:{
            getRole(){
                http.get(api.userAccount,{}).then(res=>{
                  //  console.log("头部查询的信息:"+JSON.stringify(res))
                    if(res) {
                        let data = res.data;
                        if (data.code == 200) {
                            let users = data.users;
                            if (users) {
                                let enterpriseId = users.enterpriseId;
                                let userName = users.userName;
                                this.userInfo.userName = userName;
                                this.imagesrc = users.avatar;
                                let approveStatus = users.approveStatus;
                                let nickName = users.nickName;
                                let phone = users.phone;
                                // console.log("这里额企业id" + enterpriseId)
                                // console.log("这里额用户名" + userName)
                                // console.log("企业的状态:" + approveStatus)
                                //获取用户明
                                if (enterpriseId) {
                                    localStorage.setItem('enterpriseId', enterpriseId);
                                    this.getEnterpriseInfo(enterpriseId, nickName, phone);
                                } else {
                                    //显示游客呢称
                                    this.roleName = nickName;
                                    //  localStorage.setItem('roleName','游客');
                                }
                            }

                        }
                    }
                }).catch(erro=>{
                    this.$message.error(erro)
                });
            },

             getEnterpriseInfo(id,nickName,phone){
                if(id){
                    let requestApi=api.getEnterpriseInfo+id;
                    http.get(requestApi,{}).then(res=>{
                        // console.log("企业的信息学========"+JSON.stringify(res))
                        let data=res.data;
                        if(data.code==200){
                            //企业信息中获取企业验证信息
                            let approveStatus=data.data.approveStatus;
                            //获取菜单的企业资料
                           // this. enterpriseId=data.data.enterpriseId;
                            //企业名称
                            let enterpriseName=data.data.enterpriseName;
                             //console.log("这里获取的企业名称:"+enterpriseName)
                            if(approveStatus=='success'){
                                this.roleName=enterpriseName;
                                localStorage.setItem('enterpriseName',enterpriseName);
                            }
                            else{
                                    if(!nickName){
                                        this.roleName=phone;
                                    }
                                    else{
                                        this.roleName=nickName;
                                    }
                            }


                        }

                    }).catch(erro=>{
                        this.$message.error(erro)
                    });

                }
            },
            // getRole(){
            //  let roleName=localStorage.getItem("roleName");
            //  if(roleName){
            //  this.roleName=roleName;
            //  }
            //  else{
            //      this.$message.error("获取角色失败")
            //  }
            // },
            modifyPassword(){
                let userName=this.form.userName;
                if(userName!=""&&userName!=undefined){
                    this.form={
                        userName:userName,
                        password:'',
                        newpassword:'',
                        repassword:''
                    }
                   this.dialogFormVisible=true;
                    if (this.$refs['modifyPassform']!==undefined) {
                        this.$refs['modifyPassform'].resetFields();
                    }
                }
                else{
                    this.$message.error("获取用户信息失败，请重新刷新页面")
                }

            },
            logout(){
                localStorage.removeItem('token');
                this.$router.push("/login")
            },
            info(id){
                // console.log("个人信息")
            },
            submit(from){
                // console.log("表单里的对象:"+JSON.stringify(from))
                this.$refs[from].validate(valid=>{
                    if(valid){
                        let param= {
                            newPassword:this.form.newpassword,
                            oldPassword :this.form.password
                        }
                        // console.log("准备修改密码的参数"+JSON.stringify(param));
                        http.putQuery(api.updataPasword,param).then(res=>{
                            // console.log("返沪====="+JSON.stringify(res))
                            let data=res.data;
                            if(data.code==200){
                                // console.log("密码修改成功");
                                this.$message.success("密码修改成功")
                                this.dialogFormVisible=false;
                            }
                            else{
                                this.$message.error(data.msg)
                            }
                        }).catch(err=>{
                            // console.log(err)
                        })
                    }
                    else{
                        // console.log("error submit!!");
                    }

                });



            },
            toHome(){

            },
        }
    }
</script>

<style scoped>
 .avatar{
     display: inline-block;
     position: relative;
     color: #606266;
     font-size: 14px;
      }
    .avatar img{
        width: 36px;
        height: auto;
        margin-right: 5px;
        border-radius: 100%;
        vertical-align: middle;
    }
    .avatar span{
        color: white;
    }
</style>
