<template>
    <div class="supportBody">
        <span class="ft">抱歉,暂不支持手机适配!</span>
        <span>请用电脑浏览器打开!</span>
    </div>
</template>

<script>
    export default {
        name: "NoSupport"
    }
</script>

<style scoped>
    .supportBody{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 25%;
        font-weight: bold;
        font-family: '方正舒体';
        font-size: 200rpx;
    }
    .supportBody .ft{
        margin-bottom:20px ;
    }
    html {font-size: 625%; /*100 ÷ 16 × 100% = 625%*/}

    @media screen and (min-width:360px) and (max-width:374px) and (orientation:portrait) {
        html { font-size: 703%; }
    }
    @media screen and (min-width:375px) and (max-width:383px) and (orientation:portrait) {
        html { font-size: 732.4%; }
    }
    @media screen and (min-width:384px) and (max-width:399px) and (orientation:portrait) {
        html { font-size: 750%; }
    }
    @media screen and (min-width:400px) and (max-width:413px) and (orientation:portrait) {
        html { font-size: 781.25%; }
    }
    @media screen and (min-width:414px) and (max-width:431px) and (orientation:portrait){
        html { font-size: 808.6%; }
    }
    @media screen and (min-width:432px) and (max-width:479px) and (orientation:portrait){
        html { font-size: 843.75%; }
    }
</style>