<template>
    <div class="block">
<!--        <el-carousel :height="bannerHeight+'px'">-->
<!--            <el-carousel-item v-for="(item,index) in banner" :key="index">-->
<!--                <div class="small"><img ref="bannerHeight" style="width: 100%" :src="item.src"/></div>-->
<!--            </el-carousel-item>-->
<!--        </el-carousel>-->
        <a href="https://www.yngyjt.com" target="_blank"><img ref="bannerHeight" style="width: 100%;" :src="src" /></a>
    </div>

</template>

<script>
    const  imagelist=[
        {
             src:require('@/assets/img/blance.png')
        },
        // {
        //     src:require('@/assets/img/bg4.jpg')
        // }
        // ,
        // {
        //     src:require('@/assets/img/bg5.jpg')
        // }
        // ,
        // {
        //     src:require('@/assets/img/bg6.jpg')
        // }


    ]
    export default {
        name: "customBlance",
        data(){
            return{
                src:require('@/assets/img/blance.png'),
                banner:imagelist,
                bannerHeight:"200",

            }
        },
    }
</script>

<style scoped>
    el-carousel__container{
        height: 450px;
    }
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 550px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }
</style>
