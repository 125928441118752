<template>
    <div class="container">
        <div :class="diffTitle==false?'login_header_box_active':'login_header_box'">
            <div :class="diffTitle==false?'subcontainer_active':'subcontainer'">
                <!--                <a class="login_title" href="/" target="_self">-->
                <!--                    <img src="../../assets/img/yngylogo.png" alt=""  style="max-width: 220px;max-height: 50px;">-->
                <!--                    <span></span>-->
                <!--                    <p>云南钢友招采系统</p>-->
                <!--                </a>-->
                <home-header :style="{backgroundColor:'#fff'}" :is-show-content=diffTitle></home-header>
            </div>
        </div>
        <div class="login_main_box">
            <div class="login_way_box">
                <div class="login_form active" id="tab1_content" style="padding: 30px 30px;">
                    <div class="title">用户注册</div>
                    <el-form :model="registerForm" :rules="registerRules" ref="registerForm">
                        <el-form-item class="item" prop="phone">
                            <el-input prefix-icon="el-icon-mobile-phone" v-model="registerForm.phone" placeholder="请输入手机号" :disabled="available" clearable></el-input>
                        </el-form-item>
                        <el-form-item class="item yzm" prop="smscode">
                            <el-input prefix-icon="el-icon-mobile-phone" style="width: 68%;margin-right: 10px;" placeholder="请输入短信验证码" v-model="registerForm.smscode"  clearable></el-input>
                            <el-button type="primary" @click="yzm()" :disabled="available">{{yzmtitle}}</el-button>
                        </el-form-item>
                        <el-form-item class="item yzm" prop="imagecode">
                            <div class="yzm">
                            <el-input prefix-icon="el-icon-female" v-model="registerForm.imagecode" placeholder="请输入验证码"></el-input>
                            <el-image @click="changeImage" style="width: 250px;height: 38px;margin-left: 10px;border-radius: 5px;" :src="src"/>
                            </div>
                        </el-form-item>
                        <el-form-item class="item">
                            <el-button type="primary" style="width: 100%;" @click="register('registerForm')">注册</el-button>
                        </el-form-item>
                        <el-form-item class="registerItem">
                            <div v-if="registerForm.agree==true">
                            <el-checkbox   v-model="registerForm.agree"  style=" float: left;"></el-checkbox>
                            </div>
                            <div v-else>
                                <el-checkbox   v-model="registerForm.agree"  style=" float: left;" disabled></el-checkbox>
                            </div>
                            <el-link @click="openagreen" style=" float: left;color:red;font-weight: bold;margin-left: 2px;">用户协议</el-link>
                            <a class="customLink" @click="login">已有账号登录</a>
                        </el-form-item>
                </el-form>

                </div>
            </div>
        </div>
        <!--加入对话框--->
        <el-dialog
                :visible.sync="dialogVisible"
                custom-class="mydialog"
                :modal=false
                :close-on-click-modal=false
               >
            <div class='' v-html='agreencontent'></div>
            <span slot="footer" class="dialog-footer">
           <el-button  type="info" @click="dialogVisible = false">取消</el-button>
           <el-button  type="primary" @click="agree">我同意协议</el-button>
          </span>
      </el-dialog>

    </div>
</template>

<script>
    import http from "@/utils/http";
    import api from "@/utils/api";
    import HomeHeader from "@/components/home/HomeHeader";
    import { MessageBox} from 'element-ui';
    import {validateTelephone} from '@/utils/validate'

    export default {
        name: "Register",
        components:{
            HomeHeader
        },
        mounted() {
            this.yzmImage();
            let routeName=this.$route.name;
            // // console.log("页面的路径 ："+routeName)
            if(routeName){
                if(routeName=='Register'){
                    this.diffTitle=false;
                }
            }
        },
        data(){
            return{
                registerForm: {},
                registerRules: {
                    phone:[{required: true, message: "请输入手机号码", trigger: "blur"},
                         {required: true, validator: validateTelephone, tirgger: 'blur'},
                    ],
                    smscode:[{required: true, message: "请输入手机短信验证码", trigger: "blur"},
                             {max: 6, message: "最大长度为6位字符", trigger: "blur" }],
                    imagecode:[{required: true, message: "请输入图片答案", trigger: "blur"}],

                },
                dialogVisible: false,
                isGreenShow:false,
                oneceBottom:false,
                btnStatus:false,
                yzmtitle:'验证码',
                available:false,
                imagePrefix:'data:image/gif;base64,',
                src:'',
                uuid:'',
                diffTitle:true, //是相同页页面
                agreencontent:'<h1>云南钢友信息化平台用户协议</h1>' +
                    '<p><strong>特别提示：<br />' +
                    '您在使用云南钢友工贸有限公司及其关联公司（以下简称云南钢友）提供的各项服务之前，请您务必审慎阅读、充分理解本协议各条款内容，特别是以粗体标注的部分，包括但不限于免除或者限制责任的条款。如您不同意本服务协议(或云南钢友随时对其的修改)，您可以主动停止使用云南钢友提供的服务；您一旦使用云南钢友提供的服务，即视为您已了解并完全同意本服务协议各项内容，包括云南钢友对服务协议随时所做的任何修改，并成为我们的用户。</strong></p>' +
                    '<h3><a id="%E4%B8%80%E3%80%81%E6%80%BB%E5%88%99" class="anchor" aria-hidden="true"><span class="octicon octicon-link"></span></a><strong>一、总则</strong></h3>' +
                    '<ol>' +
                    '<li>用户可以使用云南钢友各个平台、产品的单项服务，当用户使用云南钢友各单项服务时，用户的使用行为视为其对该单项服务的服务条款以及云南钢友在该单项服务中发出的各类公告的同意。</li>' +
                    '<li>云南钢友用户协议以及各个平台、产品单项服务条款和公告可由云南钢友随时更新，且无需另行通知。您在使用相关服务时，应关注并遵守其所适用的相关条款。</li>' +
                    '</ol>' +
                    '<h3><a id="%E4%BA%8C%E3%80%81%E5%B8%90%E5%8F%B7%E6%B3%A8%E5%86%8C%E4%B8%8E%E4%BD%BF%E7%94%A8" class="anchor" aria-hidden="true"><span class="octicon octicon-link"></span></a><strong>二、帐号注册与使用</strong></h3>' +
                    '<p>您使用部分云南钢友产品或服务时需要注册云南钢友帐号，当您注册和使用云南钢友帐号时应遵守下述要求：</p>' +
                    '<ol>' +
                    '<li>用户注册成功后，云南钢友将给予每个用户一个用户帐号，用户可以自主设置帐号密码。该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。</li>' +
                    '<li>您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，除云南钢友平台的具体产品对帐户有单独的注册要求外，您可获得云南钢友平台（yngy.vip网站及客户端）帐号并成为云南钢友用户，可通过云南钢友帐户使用云南钢友平台的各项产品和服务。</li>' +
                    '<li>为了方便您在云南钢友平台中享有一致性的服务，如您已经在某一云南钢友产品中登录云南钢友帐号，在您首次使用其他云南钢友产品时可能同步您的登录状态。此环节并不会额外收集、使用您的个人信息。如您想退出帐号登录，可在个人设置页面退出登录。</li>' +
                    '<li>云南钢友帐号（即云南钢友用户ID）的所有权归云南钢友工贸有限公司，用户按注册页面引导填写信息，阅读并同意本协议且完成全部注册程序后，即可获得云南钢友帐号并成为用户。用户应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实或更新不及时而引发的相关问题，云南钢友工贸有限公司不负任何责任。您可以通过云南钢友帐号设置页面查询、更正您的信息，云南钢友用户应当通过真实身份信息认证注册帐号，且用户提交的帐号名称、头像和简介等注册信息中不得出现违法和不良信息，经云南钢友审核，如存在上述情况，云南钢友将不予注册；同时，在注册后，如发现用户以虚假信息骗取帐号名称注册，或其帐号头像、简介等注册信息存在违法和不良信息的，云南钢友有权不经通知单方采取限期改正、暂停使用、注销登记、收回等措施。</li>' +
                    '<li>基于国家法律法规的规定要求或者云南钢友服务政策的要求，您在使用某些云南钢友服务时需要填写真实的姓名、身份证号等信息。若您填写的信息不完整、不正确，则可能无法使用相应服务或在使用过程中受到限制。</li>' +
                    '<li>当您使用刷脸登录、验证功能时，我们会收集并保存您的人脸照片和信息。您应按照页面的提示完成相应动作，云南钢友系统判断您的动作符合要求后，即可完成脸部拍摄。您同意仅限您本人为您本人所持有的云南钢友账户使用刷脸登录、验证服务。为了提高验证的准确性，您同意云南钢友可在必要时将您向云南钢友提供的人脸照片、脸部特征与法律法规允许的机构或政府机关授权的机构所保存的您的脸部特征进行比对核验。</li>' +
                    '<li>云南钢友帐号包括帐户名称和密码，您可使用设置的帐户名称（包括用户名、手机号、邮箱）和密码登录；在帐号使用过程中，为了保障您的帐号安全基于不同的终端以及您的使用习惯，我们可能采取不同的验证措施识别您的身份。例如您的帐户在新设备首次登录，我们可能通过密码加校验码的方式识别您的身份，验证方式包括但不限于短信验证码、服务使用信息验证。</li>' +
                    '<li>用户不应将其帐号、密码转让、出售或出借予他人使用，帐号使用权仅属于初始申请注册人，用户应对帐号内的全部行为独立承担全部责任。如因帐号转让、买卖或出借导致的帐号纠纷或云南钢友无法判断帐号归属等情况，云南钢友有权拒绝提供帐号找回，并视情况采取冻结帐号、封禁帐号等措施。</li>' +
                    '<li><strong>因您个人原因导致的帐号无法使用，如需找回云南钢友帐号，请按照云南钢友帐号找回要求提供相应的信息，并确保提供的信息合法真实有效，若提供的信息不符合要求或帐号的唯一凭证不再有效，无法通过云南钢友安全验证，因存在帐号被盗等安全风险导致无法判断帐号归属，云南钢友有权拒绝提供帐号找回服务，并视情况采取冻结帐号、封禁帐号等措施。您云南钢友帐号下绑定的手机号如因您个人原因停止使用，请尽快更改云南钢友帐号的绑定手机，否则如遇运营商二次放号，二次放号的用户可以通过您原来的手机号进行验证登录，可查看到您的全部帐号信息及产品使用信息，您的云南钢友帐号将无法继续使用，帐号内个人信息也有泄露的风险，请您务必及时更换绑定手机，如因未及时换绑手机号导致帐号丢失或个人信息泄露的责任由您自行承担，云南钢友有权拒绝提供帐号找回服务，并视情况采取冻结帐号、封禁帐号等措施。</strong></li>' +
                    '<li>在需要终止使用云南钢友帐号服务时，符合以下条件的，您可以申请注销您的云南钢友帐号。' +
                    '<ul>' +
                    '<li>您仅能申请注销您本人的帐号，并依照云南钢友的流程进行注销；</li>' +
                    '<li>您仍应对您在注销帐号前且使用云南钢友服务期间的行为承担相应责任，同时云南钢友仍可保存您注销前的相关信息；</li>' +
                    '<li>注销成功后，帐号信息、个人身份证信息、交易记录、会员权益等将无法恢复或提供；</li>' +
                    '</ul>' +
                    '</li>' +
                    '<li><strong>为了防止资源占用，如您连续六个月未使用您的云南钢友帐号或未通过云南钢友认可的其他方式登录过您的云南钢友帐户，云南钢友有权对该帐户进行注销，您将不能再通过该帐号登录名登录本网站或使用相关服务。</strong></li>' +
                    '<li>我们将按照云南钢友的隐私权保护声明使用您在注册云南钢友帐户、使用云南钢友服务中提交、留存的信息。您保证在您使用云南钢友的所有产品和服务前，已经充分了解并同意云南钢友可以据此处理用户信息。</li>' +
                    '<li>为了满足相关法律法规的要求和保障您的帐号安全，尤其是您在进行帐号解绑、注销等敏感操作时，我们会将您提交的个人身份信息发送至银行、运营商等机构进行验证查询，以核实您身份的真实性。</li>' +
                    '</ol>' +
                    '<h3><a id="%E4%B8%89%E3%80%81%E4%BD%BF%E7%94%A8%E8%A7%84%E5%88%99" class="anchor" aria-hidden="true"><span class="octicon octicon-link"></span></a><strong>三、使用规则</strong></h3>' +
                    '<ol>' +
                    '<li>用户在使用云南钢友的服务时，必须遵守《网络安全法》、《计算机信息网络国际联网安全保护管理办法》、《互联网信息服务管理办法》、《互联网电子公告服务管理规定》、《维护互联网安全的决定》、《互联网新闻信息服务管理规定》等中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为:' +
                    '<ul>' +
                    '<li>上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：' +
                    '<ul>' +
                    '<li>反对宪法所确定的基本原则的；</li>' +
                    '<li>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</li>' +
                    '<li>损害国家荣誉和利益的；</li>' +
                    '<li>煽动民族仇恨、民族歧视、破坏民族团结的；</li>' +
                    '<li>破坏国家宗教政策，宣扬邪教和封建迷信的；</li>' +
                    '<li>散布谣言，扰乱社会秩序，破坏社会稳定的；</li>' +
                    '<li>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>' +
                    '<li>侮辱或者诽谤他人，侵害他人合法权利的；</li>' +
                    '<li>含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</li>' +
                    '<li>与电鱼、毒鱼、炸鱼等破坏渔业资源和生态环境的捕捞行为相关的违法违规内容（包括但不限于文字、图片、动态、视频等）；</li>' +
                    '<li>含有可能涉及侵害国家主权和领土完整、违反国家关于地图绘制、发布相关规定的内容；</li>' +
                    '<li>含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</li>' +
                    '</ul>' +
                    '</li>' +
                    '<li>不得为任何非法目的而使用网络服务系统；</li>' +
                    '<li>不利用云南钢友的服务从事以下活动：' +
                    '<ul>' +
                    '<li>未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</li>' +
                    '<li>未经允许，对计算机信息网络功能进行删除、修改或者增加的；</li>' +
                    '<li>未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</li>' +
                    '<li>故意制作、传播计算机病毒等破坏性程序的；</li>' +
                    '<li>其他危害计算机信息网络安全的行为。</li>' +
                    '</ul>' +
                    '</li>' +
                    '</ul>' +
                    '</li>' +
                    '<li>用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿云南钢友与合作公司、关联公司，并使之免受损害。对此，云南钢友有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收云南钢友帐号、追究法律责任等措施。对恶意注册云南钢友帐号或利用云南钢友帐号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，云南钢友有权回收其帐号。同时，云南钢友会视司法部门的要求，协助调查。</li>' +
                    '<li>用户不得对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。</li>' +
                    '<li>用户须对自己在使用云南钢友服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在云南钢友首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予云南钢友等额的赔偿。</li>' +
                    '<li>用户在使用云南钢友服务时遵守以下互联网底线：' +
                    '<ul>' +
                    '<li>法律法规底线</li>' +
                    '<li>社会主义制度底线</li>' +
                    '<li>国家利益底线</li>' +
                    '<li>公民合法权益底线</li>' +
                    '<li>社会公共秩序底线</li>' +
                    '<li>道德风尚底线</li>' +
                    '<li>信息真实性底线</li>' +
                    '</ul>' +
                    '</li>' +
                    '</ol>' +
                    '<h3><a id="%E5%9B%9B%E3%80%81%E6%9C%8D%E5%8A%A1%E5%86%85%E5%AE%B9" class="anchor" aria-hidden="true"><span class="octicon octicon-link"></span></a><strong>四、服务内容</strong></h3>' +
                    '<ol>' +
                    '<li>云南钢友网络服务的具体内容由云南钢友公司根据实际情况提供。</li>' +
                    '<li><strong>除非本服务协议另有其它明示规定，云南钢友所推出的新产品、新功能、新服务，均受到本服务协议之规范。</strong></li>' +
                    '<li><strong>云南钢友有权于任何时间暂时或永久修改或终止本服务（或其任何部分），而无论其通知与否，云南钢友对用户和任何第三人均无需承担任何责任。</strong></li>' +
                    '<li><strong>您同意云南钢友工贸有限公司基于其自行之考虑，因任何理由，包含但不限于长时间（超过六个月）未使用，或云南钢友工贸有限公司认为您已经违反本服务协议的文字及精神，终止您的密码、帐号或本服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除并删除。您同意依本服务协议任何规定提供之本服务，无需进行事先通知即可中断或终止，您承认并同意，云南钢友工贸有限公司可立即关闭或删除您的帐号及您帐号中所有相关信息及文件，及/或禁止继续使用前述文件或本服务。此外，您同意若本服务之使用被中断或终止或您的帐号及相关信息和文件被关闭或删除，云南钢友工贸有限公司对您或任何第三人均不承担任何责任。</strong></li>' +
                    '</ol>' +
                    '<h3><a id="%E4%BA%94%E3%80%81%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E5%92%8C%E5%85%B6%E4%BB%96%E5%90%88%E6%B3%95%E6%9D%83%E7%9B%8A%EF%BC%88%E5%8C%85%E6%8B%AC%E4%BD%86%E4%B8%8D%E9%99%90%E4%BA%8E%E5%90%8D%E8%AA%89%E6%9D%83%E3%80%81%E5%95%86%E8%AA%89%E6%9D%83%EF%BC%89" class="anchor" aria-hidden="true"><span class="octicon octicon-link"></span></a><strong>五、知识产权和其他合法权益（包括但不限于名誉权、商誉权）</strong></h3>' +
                    '<ol>' +
                    '<li>用户权利保护' +
                    '<ul>' +
                    '<li>若您认为他人云南钢友帐号（包括云南钢友帐号的头像、用户名、昵称、简介）的使用侵犯了您的合法权益，请按照以下说明向云南钢友提供资料∶</li>' +
                    '<li>请注意：如果权利通知的陈述失实，权利通知提交者将承担对由此造成的全部法律责任（包括但不限于赔偿各种费用及律师费）。如果上述个人或单位不确定网络上可获取的资料是否侵犯了其知识产权和其他合法权益，云南钢友建议该个人或单位首先咨询专业人士。</li>' +
                    '<li>为了云南钢友有效处理上述个人或单位的权利通知，请使用以下格式（包括各条款的序号）：' +
                    '<ul>' +
                    '<li>权利人对涉嫌侵权内容拥有合法权益的权属证明；</li>' +
                    '<li>请充分、明确地描述被侵犯了合法权益的情况并请提供涉嫌侵权的第三方帐号信息（包括用户名、昵称、头像、简介）和网址（如果有）。</li>' +
                    '<li>请指明涉嫌侵权帐号的哪些内容侵犯了第2项中列明的权利。</li>' +
                    '<li>请提供权利人具体的联络信息，包括姓名、身份证或护照复印件（对自然人）、单位登记证明复印件（对单位）、通信地址、电话号码、传真和电子邮件。</li>' +
                    '<li>请在权利通知中加入如下关于通知内容真实性的声明： “我保证，本通知中所述信息是充分、真实、准确的，如果本权利通知内容不完全属实，本人将承担由此产生的一切法律责任。”</li>' +
                    '<li>请您签署该文件，如果您是依法成立的机构或组织，请您加盖公章。</li>' +
                    '<li>请您把以上资料的原件扫描件发送至：<a href="mailto:yngy@yngyjt.com">yngy@yngyjt.com</a></li>' +
                    '</ul>' +
                    '</li>' +
                    '</ul>' +
                    '</li>' +
                    '<li>对于用户通过云南钢友服务（包括但不限于钢友官网、云南钢友信息化平台、云南钢友客户智能服务平台等）上传到云南钢友平台或网站上可公开获取区域的任何内容，用户同意云南钢友在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。</li>' +
                    '<li>云南钢友拥有本平台内所有资料的版权。任何被授权的浏览、复制、打印和传播属于本平台内的资料必须符合以下条件：' +
                    '<ul>' +
                    '<li>所有的资料和图象均以获得信息为目的；</li>' +
                    '<li>所有的资料和图象均不得用于商业目的；</li>' +
                    '<li>所有的资料、图象及其任何部分都必须包括此版权声明；</li>' +
                    '<li>本平台所有的产品、技术与所有程序均属于云南钢友工贸有限公司知识产权，在此并未授权。“云南钢友”及相关图形等为云南钢友工贸有限公司的注册商标。</li>' +
                    '<li>未经云南钢友工贸有限公司许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用。否则，云南钢友将依法追究法律责任。</li>' +
                    '</ul>' +
                    '</li>' +
                    '</ol>' +
                    '<h3><a id="%E5%85%AD%E3%80%81%E5%85%B6%E4%BB%96" class="anchor" aria-hidden="true"><span class="octicon octicon-link"></span></a><strong>六、其他</strong></h3>' +
                    '<ol>' +
                    '<li>本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</li>' +
                    '<li>如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向云南钢友工贸有限公司所在地云南省昆明市呈贡区人民法院提起诉讼。</li>' +
                    '<li>云南钢友未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。</li>' +
                    '<li>如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</li>' +
                    '<li>当您通过云南钢友帐号登录、使用第三方产品或服务时，在遵守本协议基础上，您同时需要遵守该第三方产品或服务的具体使用规则，例如：当您使用深圳腾讯计算机系统有限公司微信服务时，您需要同时遵守深圳腾讯计算机系统有限公司的《软件许可及服务协议》。<br />' +
                    '<strong>请您在发现任何违反本服务协议之情形时，通知云南钢友工贸有限公司，电话：0871-67416608，邮箱：<a href="mailto:yngy@yngyjt.com">yngy@yngyjt.com</a>。</strong></li>'

            }
        },
        methods:{
               agree(){
                  this.dialogVisible=false;
                  this.$set(this.registerForm, 'agree', true)
               },
                openagreen() {
                    this.dialogVisible=true;
            },
            changeImage(){
                this.yzmImage();
            },
            yzmImage(){
                http.get(api.captcha,{}).then(res=>{
                    let data=res.data;
                    if(data.code==200){
                         let imageBase=data.img;
                         let src=this.imagePrefix+imageBase;
                         this.src=src;
                         this.uuid=data.uuid;
                    }
                    else{
                        this.$message.error(data.msg)
                    }
                }).catch(err=>{
                    // // console.log(err)
                })
            },
            yzm(){
                let phone=this.registerForm.phone;
                if(phone) {
                         let regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|16[6]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
                    //   let regMobile =/^(0|86|17951)?(13[0-9]|15[012356789]|16[6]|19[89]]|17[01345678]|18[0-9]|14[579])[0-9]{8}$/;
                    let val = new RegExp(regMobile).test(phone)
                    if (val) {
                    let registerPhone = api.registerSms + phone;
                    http.get(registerPhone, {}).then(res => {
                        this.available = true;
                        let data = res.data;
                        if (data.code == 200) {
                            let i = 120;
                            let y = setInterval(() => {
                                i = i - 1;
                                    this.yzmtitle = i+'秒';
                                if (i == 1) {
                                    clearInterval(y);
                                    this.yzmtitle = '验证码';
                                    this.available = false;
                                }
                            }, 1000)
                        } else {
                            this.$message.error(data.msg)
                        }
                    }).catch(err => {
                        // console.log(err)
                    })
                   }
                }
            },
            login(){
                this.$router.push("/login")
            },
            register(from){
                this.$refs[from].validate(valid=>{
                if(valid){
                    let agree=this.registerForm.agree;
                    if(agree!=true){
                        this.$message.error("请先勾选用户协议");
                        return;
                    }
                 // console.log(valid)
                 // console.log("success submit!!");
                    let param= {
                        uuid : this.uuid,
                        phone : this.registerForm.phone,
                        code:this.registerForm.smscode,
                        captcha :this.registerForm.imagecode
                    }
                    // console.log("准备注册的参数"+JSON.stringify(param));
                    http.post(api.register,param).then(res=>{
                        let data=res.data;
                        if(data.code==200){
                           let userId=data.userId;
                           if(userId){
                               this.$message.success("注册成功");
                               this.$router.push("/login");
                           }
                           else{
                               this.$message.error(data.msg)
                           }
                        }
                        else{
                            let msg=data.msg;
                            if(msg=='验证码错误'){
                                this.yzmImage();
                            }
                            this.$message.error(data.msg)
                        }
                    }).catch(err=>{
                        // console.log(err)
                    })
                 }
                  else{
                   // console.log("error submit!!");
                }

                });

            }

        }
    }
</script>

<style>
    .mydialog{
        margin-top: 15vh;
        width: 70%;
        height: 600px;
        overflow-y: scroll;
    }
    .myClass{
        width:700px!important;
    }
    .myknowdefault{
        color: #000!important;
        width: 200px;
        display: none!important;
    }
    mychoose{
        display:block!important;
    }
    /*.el-message-box{*/
    /*    display: inline-block;*/
    /*    width: 720px;*/
    /*    padding-bottom: 10px;*/
    /*    vertical-align: middle;*/
    /*    background-color: #FFF;*/
    /*    border-radius: 4px;*/
    /*    border: 1px solid #EBEEF5;*/
    /*    font-size: 18px;*/
    /*    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);*/
    /*    text-align: left;*/
    /*    overflow: hidden;*/
    /*    -webkit-backface-visibility: hidden;*/
    /*    backface-visibility: hidden;*/

    /*    }*/
    /*.el-message-box__header {*/
    /*    padding: 13px;*/
    /*    border-bottom: #4970ad 2px solid;*/
    /*}*/
    /*.el-message-box__title{*/
    /*    font-size: 20px;*/
    /*    color: #4970ad;*/
    /*}*/

    /*.el-message-box__headerbtn{*/
    /*    width: 30px!important;*/
    /*    height: 30px!important;*/
    /*    background: white;*/
    /*    border: 2px solid #999;*/
    /*    top: 10px;*/
    /*    border-radius: 50%;*/
    /*}*/
    /*.el-message-box__headerbtn .el-message-box__close{*/
    /*    color: #999999!important;*/
    /*    line-height: 26px;*/
    /*    font-weight: bold;*/
    /*    font-size: 16px;*/
    /*}*/
</style>

<style scoped>

    .myClass{
        width:700px;
    }
    .container{
        background: #F0F2F5 url('../../assets/img/login_bg.jpg') center center no-repeat;
        background-size: cover;
        height: 100%;
        width:100%;

    }
    .subcontainer{
        width: 80%;
        padding: 0;
        margin: 0 auto;
        position: relative;
    }
    .subcontainer_active{
        width: 100%;
        padding: 0;
        margin: 0 auto;
        position: relative;
    }
    .login_header_box{
        background: rgba(255,255,255,.8);
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
    }
    login_header_box_active{
        /*background: #fff;*/
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;

    }
    .login_title{
        display: flex;
        align-items: center;
    }
    .login_title span {
        width: 2px;
        height: 18px;
        background: #adaeaf;
        margin: 0 16px;
        display: block;
    }
    .login_title p {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 3px;
        text-decoration: none;
    }
    .login_main_box {
        width: 400px;
        min-height: 315px;
        background: #ffffff;
        box-shadow: 0px 10px 24px rgb(0 0 0 / 30%);
        border-radius: 8px;
        position: absolute;
        right: 15%;
        top: 30%;
        z-index: 1;
    }
    .title{
        font-size: 18px;
        font-weight: bold;
        width: 100%;
        text-align: center;
    }
    .item{
        margin: 20px;
    }

    .login_form .textbox_ui{
        position: relative;
        border-bottom: 1px solid rgba(187, 183, 183, 0.5);
    }
    .registerItem{
        margin: -10px 20px;
        text-align: right;
    }
    .yzm{
        display: flex;
        flex-direction: row
    }
    .customLink{
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        position: relative;
        text-decoration: none;
        outline: 0;
        padding: 0;
        color: #409EFF;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
    }
    .el-form-item__content{
        display: flex;
    }


</style>
