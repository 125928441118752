<template>
    <div>
    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-click-modal=false :close-on-press-escape=false>
        <el-upload
                class="upload-demo"
                action="#"
                :on-preview="handlePreview"
                :http-request="imgUpload"
                :on-remove="handleRemove"
                accept=".jpeg,.jpg,.png"
                :file-list="from.fileList"
                list-type="picture">
            <i class="el-icon-plus avatar-uploader-icon" style="font-size: 70px;border: 1px solid #2d2d2d"></i>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
        <el-button type="primary" style="float: right;position: absolute;top:10px;right:120px;" @click="cancel">取消</el-button>
        <el-button type="primary" style="float: right;position: absolute;top:10px;right:10px;" @click="submit">确认上传</el-button>
    </el-dialog>
    <!--显示图片对话框--->
    <el-dialog :title="title" ref="showImage" :visible.sync="showImageDialogVisible">
        <el-image :src="ImageSrc" />
    </el-dialog>
    </div>
</template>

<script>
    import http from "@/utils/http";
    import api from "@/utils/api";

    export default {
        name: "WarrantyBook",
        data(){
            return{
                dialogFormVisible:false,
                title:'质保书',
                from:'', //存一下临时的表单对象
                ImageSrc:'',
                showImageDialogVisible:false
            }
        },
        methods:{
            //文件上传
            async imgUpload(info){
               // console.log("原始对象"+info)
              //  console.log("原始对象"+JSON.stringify(info))
                if(info){
                    let file=info.file;
                    let fileName=info.file.name;
                    // console.log("file"+file)
                    // console.log("fileName"+fileName)
                    // 截取上传文件的后缀名
                    let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
                    // 判断文件名的类型
                    if (fileType=="png"||fileType=="jpeg"||fileType=="jpg") {
                        let formData = new FormData();
                        formData.append('authFile', file);
                        //调用上传接口
                        let imageurl=await http.upload(api.uploadFile,formData).then(res=>{
                            // console.log("请求服务"+JSON.stringify(res));
                            let data=res.data;
                            if(data.code==200){
                                let imageUrl=data.imgUrl;
                               // console.log("图片路径:"+imageUrl)
                                return imageUrl;
                            }

                        }).catch(err=>{
                            // console.log("错误拦截"+JSON.stringify(err))
                        })

                        if(imageurl){
                            let fileList=this.from.fileList;
                            if(fileList==undefined){
                                this.from.fileList=[];
                            }
                            let filelength=this.from.fileList.length;
                            let id;
                            if(filelength<=0){
                                id=0;
                            }
                            else{
                                id=filelength;
                            }
                            let imageObj={
                                id:id,
                                name:fileName,
                                url:imageurl
                            }
                            this.from.fileList.push(imageObj);

                        }
                        //console.log("数据:文件个数"+JSON.stringify(this.from))
                    }
                    else{
                        this.$message.error('上传文件仅支持jpeg,jpg,png格式');
                        this.from.fileList.push(null);
                    }




                }

            },
            WarrantyBookDialogVisible(from){
                if(from){
                //     console.log("这里的值22222:"+JSON.stringify(from))
                    let kindName=from.product_name;
                    this.title=kindName+'质保书:';
                    this.dialogFormVisible = true;
                    this.from=from;
                }
            },
            cancel(){
                this.$emit("receive",this.from);
                this.dialogFormVisible=false;
            },
            submit(){
                // console.log("临时表单对象:"+JSON.stringify(this.from));
                 let fileList=this.from.fileList;
                //console.log("当前的文件对象:"+JSON.stringify(fileList));
                if(fileList) {
                    if (fileList.length > 0) {
                        this.from.fileList = fileList;
                    }
                }
              //  console.log("临时表单对象List:"+JSON.stringify(this.from));
               this.$emit("receive",this.from);
               this.dialogFormVisible=false;
            },
            handleRemove(file, fileList) {
             //   console.log("这里的"+JSON.stringify(file))
              //  console.log(file, fileList);
                let id=file.id;
              //  console.log("这里的id"+id)
                for(let i=0;i<fileList.length;i++){
                     if(id==fileList[i].id){
                         fileList.splice(i,1);
                     }
                }
                this.from.fileList=fileList;
               // console.log("新的文件个数:"+JSON.stringify(fileList))
            },
            handlePreview(file) {
             //   console.log("点击查看文件"+JSON.stringify(file))
             //   console.log(file);
                this.ImageSrc=file.url;
                this.showImageDialogVisible=true;
            },

        }
    }
</script>

<style scoped>

</style>
