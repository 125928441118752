<template>
    <div class="customContainer">
        <el-card class="box-card customContainer">
            <div slot="header" class="clearfix">
                <bread-header jup-path="/main/enterprise" jup-page="企业认证" />
            </div>
            <div class="inner">
                <el-form :model="enterpriseMode"  :rules="enterpriseFrom" ref="enterpriseFrom" :hide-required-asterisk=true>
                   <el-form-item prop="name" >
                       <label slot="label">企业名称:&nbsp;&nbsp;&nbsp;&nbsp;</label>
                       <el-input prefix-icon="el-icon-tickets" v-model="enterpriseMode.name" placeholder="请输入企业名称" clearable></el-input>
                   </el-form-item>
                    <el-form-item prop="orgCode">
                        <label slot="label">信用代码:&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <el-input prefix-icon="el-icon-tickets" v-model="enterpriseMode.orgCode" placeholder="请输入企业信用代码" clearable></el-input>
                    </el-form-item>
                    <el-form-item  prop="legalPersonName">
                        <label slot="label">企业法人:&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <el-input prefix-icon="el-icon-tickets" v-model="enterpriseMode.legalPersonName" placeholder="请输入企业法人" clearable></el-input>
                    </el-form-item>
                    <el-form-item prop="contactName">
                        <label slot="label">负责人:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <el-input prefix-icon="el-icon-tickets" v-model="enterpriseMode.contactName" placeholder="请输入负责人" clearable></el-input>
                    </el-form-item>
                    <el-form-item prop="contactPhone">
                        <label slot="label">负责人电话:&nbsp;</label>
                        <el-input prefix-icon="el-icon-tickets" v-model="enterpriseMode.contactPhone" placeholder="请输入负责人电话" clearable></el-input>
                    </el-form-item>
                    <el-form-item prop="bankName">
                        <label slot="label">开户银行:&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <el-input prefix-icon="el-icon-tickets" v-model="enterpriseMode.bankName" placeholder="请输入开户银行" clearable></el-input>
                    </el-form-item>
                    <el-form-item prop="bankCode">
                        <label slot="label">开户行账号:&nbsp;</label>
                        <el-input prefix-icon="el-icon-tickets" v-model="enterpriseMode.bankCode" placeholder="请输入开户行账号" clearable></el-input>
                    </el-form-item>
                    <el-form-item prop="enterpriseAddress">
                        <label slot="label">企业地址:&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <el-input prefix-icon="el-icon-tickets" v-model="enterpriseMode.enterpriseAddress" placeholder="请输入企业地址" clearable></el-input>
                    </el-form-item>
<!--                    <el-form-item prop="">-->
<!--                        <label slot="label">企业分类:&nbsp;&nbsp;&nbsp;&nbsp;</label>-->
<!--                        <el-link type="primary">选择企业类型</el-link>-->
<!--                    </el-form-item>-->
                    <el-form-item prop="enterpriseImg">
                        <label slot="label">上传营业执照:</label>
                        <el-upload
                                class="avatar-uploader"
                                drag
                                action="#"
                                accept=".jpeg,.jpg,.png"
                                :http-request="imgUpload"
                                :show-file-list=false
                                :on-success="uploadSuccess"
                                :on-error="failStatus"
                                >
                            <img v-if="enterpriseMode.enterpriseImg" :src="enterpriseMode.enterpriseImg" class="avatar" style="height: 200px;height:200px;">
<!--                            <div v-else>-->
<!--                            <i class="el-icon-upload"></i>-->
<!--                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>-->
<!--                            </div>-->
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            <div slot="tip" class="el-upload__tip" style="color:#000">
                                                            提示：仅允许上传“jpg”、“jpeg”、“png”格式文件！
<!--                                提示：仅允许上传jpeg格式文件！-->
                            </div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item>
                        <el-button v-if="enterpriseId==''" type="primary" style="width: 20%;margin-left: 25%;" @click="save('enterpriseFrom')">保存资料</el-button>
                         <div v-else>
                        <el-button type="primary" style="width: 15%;margin-left: 20%;" @click="update('enterpriseFrom')">重新保存资料</el-button>
                        <el-button type="primary" style="width: 15%;margin-left: 5%;" @click="rebuild('enterpriseFrom')">保存确定并提交</el-button>
                         </div>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>
    import BreadHeader from "@/components/breadHeader/BreadHeader";
    import {validateTelephone,validateTongYi} from "@/utils/validate";
    import http from "@/utils/http";
    import api from "@/utils/api";

    export default {
        inject:['reload'],
        name: "AddEnterprise",
        components: {
            BreadHeader
        },
        mounted() {
            let enterpriseId=this.$route.params.id;
            if(enterpriseId){
                this.enterpriseId=enterpriseId;
                this._initFindEnterprise(enterpriseId);
            }
            // console.log("获取到参数"+JSON.stringify(enterpriseId))
        },
        data(){
            return{
              //  enterpriseImg:'',
                enterpriseId:'',//对应操作
                enterpriseMode:{},
                enterpriseFrom:{
                    name:[{required: true, message: "请输入企业名称", trigger: "blur"},],
                    orgCode:[
                        {required: true, message: "请输入企业统一信用代码", trigger: "blur"},
                        {required: true, validator:validateTongYi, trigger: "blur"},

                    ],
                    legalPersonName:[{required: true, message: "请输入法人", trigger: "blur"},],
                    contactName:[{required: true, message: "请输入负责人", trigger: "blur"},],
                    contactPhone:[{required: true, message: "请输入负责人电话", trigger: "blur"},
                        {required: true, validator: validateTelephone, tirgger: 'blur'},
                    ],
                    // bankName:[{required: true, message: "请输入请输入开户银行", trigger: "blur"},],
                    // bankCode:[{required: true, message: "请输入开户行账号", trigger: "blur"},],
                    enterpriseAddress:[{required: true, message: "请输入企业地址", trigger: "blur"},],
                    // enterpriseImg:[{required: true, message: "请上传企业营业图片", trigger: "blur"},],

                },
            }
        },
        methods:{
            async imgUpload(info){
                // console.log("原始对象"+info)
                if(info){
                    let file=info.file;
                    let fileName=info.file.name;
                    // console.log("file"+file)
                    // console.log("fileName"+fileName)
                    let formData = new FormData();
                    formData.append('authFile', file);
                    // 调用上传接口
                    await http.upload(api.uploadFile,formData).then(res=>{
                        // console.log("请求服务"+JSON.stringify(res));
                        let data=res.data;
                        if(data.code==200){
                            let imageUrl=data.imgUrl;
                            // console.log("图片路径123:"+imageUrl)
                            this.enterpriseMode.enterpriseImg=imageUrl;
                            this.$forceUpdate();


                        }

                    }).catch(err=>{
                        // console.log("错误拦截"+JSON.stringify(err))
                    })
                }

            },
            uploadSuccess(y){
                // console.log(JSON.stringify(y))
            },
            failStatus(){
                // console.log("失败")

            },
             _initFindEnterprise(id){
                 let url=api.userAuth+'/'+id;
                 http.get(url, {}).then(res=>{
                     // console.log("更具id来修改的参数 ："+JSON.stringify(res))
                     let data=res.data;
                     if(data.code==200){

                         let innerdata=data.data;
                         // console.log("修改-----------------参数内容:"+JSON.stringify(innerdata));
                        // this.$message.success("资料提交成功，请等待审核......");
                         //this.$router.push("/main/enterprise")
                         let obj= {
                              enterpriseId : innerdata.enterpriseId,
                              name : innerdata.enterpriseName,
                              orgCode : innerdata.orgCode,
                              legalPersonName : innerdata.legalPersonName,
                              contactName : innerdata.contactName,
                              contactPhone : innerdata.contactPhone,
                              bankName : innerdata.bankName,
                              bankCode : innerdata.bankCode,
                              enterpriseAddress : innerdata.enterpriseAddress,
                              enterpriseImg : innerdata.businessLicensePicture
                         }

                         this.enterpriseMode=obj;
                     }
                     else{
                         this.$message.error(data.msg)
                     }
                 }).catch(err=>{
                     // console.log(err)
                 })
             },
            save(from){
                this.$refs[from].validate(valid=>{
                    if(valid){
                        // console.log(valid)
                        // console.log("success submit!!");
                        let bankName=this.enterpriseMode.bankName;
                        let bankCode=this.enterpriseMode.bankCode;
                        let param= {
                            enterpriseName:this.enterpriseMode.name,
                            orgCode:this.enterpriseMode.orgCode,
                            contactName:this.enterpriseMode.contactName,
                            contactPhone:this.enterpriseMode.contactPhone,
                            enterpriseAddress:this.enterpriseMode.enterpriseAddress,
                            businessLicensePicture:this.enterpriseMode.enterpriseImg,
                            legalPersonName:this.enterpriseMode.legalPersonName,
                        }
                        if(bankName){
                            param.bankName=bankName;
                        }
                        if(bankCode){
                            param.bankCode=bankCode;
                        }
                        // console.log("准备企业信息的参数"+JSON.stringify(param));
                        http.post(api.userAuth,param).then(res=>{
                            // console.log("提交后返回的参数 ："+JSON.stringify(res))
                            let data=res.data;
                            if(data.code==200){
                               this.$message.success("资料提交成功，请等待审核......");
                                this.$router.push("/main/enterprise")
                            }
                            else{
                                this.$message.error(data.msg)
                            }
                        }).catch(err=>{
                            // console.log(err)
                        })
                    }
                    else{
                        // console.log("error submit!!");
                    }

                });
            },
            update(from){
                this.$refs[from].validate(valid=>{
                    if(valid){
                        // console.log(valid)
                        // console.log("success submit!!");
                        let param= {
                            enterpriseId:this.enterpriseId,
                            enterpriseName:this.enterpriseMode.name,
                            orgCode:this.enterpriseMode.orgCode,
                            contactName:this.enterpriseMode.contactName,
                            legalPersonName:this.enterpriseMode.legalPersonName,
                            contactPhone:this.enterpriseMode.contactPhone,
                            businessLicensePicture:this.enterpriseMode.enterpriseImg,
                            enterpriseAddress:this.enterpriseMode.enterpriseAddress,

                        }
                        // console.log("准备更新资料的参数"+JSON.stringify(param));
                        http.put(api.userAuth,param).then(res=>{
                            // console.log("提交后返回的参数 ："+JSON.stringify(res))
                            let data=res.data;
                            if(data.code==200){
                               this.$message.success("资料更新成功......");
                                this.$router.push("/main/enterprise")
                            }
                            else{
                                this.$message.error(data.msg)
                            }
                        }).catch(err=>{
                            // console.log(err)
                        })
                    }
                    else{
                        // console.log("error submit!!");
                    }

                });
            },
            rebuild(from){
                this.$refs[from].validate(valid=>{
                    if(valid){
                        // console.log(valid)
                        // console.log("success submit!!");
                        let param= {
                            enterpriseId:this.enterpriseId,
                            enterpriseName:this.enterpriseMode.name,
                            orgCode:this.enterpriseMode.orgCode,
                            contactName:this.enterpriseMode.contactName,
                            legalPersonName:this.enterpriseMode.legalPersonName,
                            contactPhone:this.enterpriseMode.contactPhone,
                            businessLicensePicture:this.enterpriseMode.enterpriseImg,
                            enterpriseAddress:this.enterpriseMode.enterpriseAddress,
                        }
                        // console.log("准备更新资料的参数"+JSON.stringify(param));
                        http.put(api.userAuth,param).then(res=>{
                            // console.log("提交后返回的参数 ："+JSON.stringify(res))
                            let data=res.data;
                            if(data.code==200){

                                let url=api.beSure+this.enterpriseId;
                                http.get(url,{}).then(res=>{
                                    // console.log("参数 ："+JSON.stringify(res))
                                    let data=res.data;
                                    if(data.code==200){
                                        this.$message.success("资料更新成功......");
                                        this.$router.push("/main/enterprise")

                                    }
                                    else{
                                        this.$message.error(data.msg)
                                    }
                                }).catch(err=>{
                                    // console.log(err)
                                })

                                //this.$message.success("资料更新成功......");
                               // this.$router.push("/main/enterprise")
                            }
                            else{
                                this.$message.error(data.msg)
                            }
                        }).catch(err=>{
                            // console.log(err)
                        })
                    }
                    else{
                        // console.log("error submit!!");
                    }

                });
            }

        }

    }
</script>

<style scoped>
    .el-input{
        width:60%;
    }
    .inner{
        display: flex;
        flex-direction: column;
    }
</style>
